<footer>
  <div class="footer-divider"></div>
  <div class="footer-content">
    <a routerLink="/legal" class="footer-title-text link-white">EUALA & Privacy</a>
    <a routerLink="/careers" class="footer-title-text link-white">Careers</a>
    <a routerLink="/contact" class="footer-title-text link-white">Contact Us</a>
    <div>
      <div class="footer-title-text">Check Us Out On</div>
      <div class="footer-icon-container">
        <a class="lift" href="https://www.facebook.com/PlayPlasmaGames/" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'facebook-square']"></fa-icon>
        </a>
        <a class="lift" href="https://twitter.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'twitter-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.linkedin.com/company/plasma-games/" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'linkedin']"></fa-icon>
        </a>
        <a class="lift" href="https://www.instagram.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'instagram-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.youtube.com/channel/UC7qesg-ffGwv9FkTOoR8uaQ" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'youtube-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.pinterest.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'pinterest-square']"></fa-icon>
        </a>
        <a class="lift" href="https://discord.gg/RAyXa25" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'discord']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</footer>
