import { SideNavItems, SideNavSection } from '@modules/navigation/models';

export const sideNavSections: SideNavSection[] = [
  {
    text: 'CORE',
    items: ['dashboards'],
  },
  {
    text: 'APP VIEWS',
    items: ['pages', 'flows'],
  },
  {
    text: 'UI TOOLKIT',
    items: ['layout', 'components', 'utilities'],
  },
  {
    text: 'PLUGINS',
    items: ['charts', 'tables'],
  },
];

export const sideNavItems: SideNavItems = {
  charts: {
    icon: 'bar-chart',
    link: '/charts',
    text: 'Charts',
  },
  components: {
    icon: 'package',
    submenu: [
      {
        link: '/sb-dashboard/components/alerts',
        text: 'Alerts',
      },
      {
        link: '/sb-dashboard/components/avatars',
        text: 'Avatars',
      },
      {
        link: '/sb-dashboard/components/badges',
        text: 'Badges',
      },
      {
        link: '/sb-dashboard/components/buttons',
        text: 'Buttons',
      },
      {
        link: '/sb-dashboard/components/cards',
        text: 'Cards',
        updated: true,
      },
      {
        link: '/sb-dashboard/components/dropdowns',
        text: 'Dropdowns',
      },
      {
        link: '/sb-dashboard/components/forms',
        text: 'Forms',
        updated: true,
      },
      {
        link: '/sb-dashboard/components/modal',
        text: 'Modals',
      },
      {
        link: '/sb-dashboard/components/navigation',
        text: 'Navigation',
      },
      {
        link: '/sb-dashboard/components/progress',
        text: 'Progress',
      },
      {
        link: '/sb-dashboard/components/toasts',
        text: 'Toasts',
      },
      {
        link: '/sb-dashboard/components/step',
        text: 'Step',
        new: true,
      },
      {
        link: '/sb-dashboard/components/timeline',
        text: 'Timeline',
        new: true,
      },
      {
        link: '/sb-dashboard/components/tooltips',
        text: 'Tooltips',
      },
    ],
    text: 'Components',
  },
  dashboards: {
    icon: 'activity',
    submenu: [
      {
        link: '/dashboard',
        text: 'Default',
        updated: true,
      },
      {
        link: '/sb-dashboard/multipurpose',
        text: 'Multipurpose',
        updated: true,
      },
      {
        link: '/sb-dashboard/affiliate',
        text: 'Affiliate',
        updated: true,
      },
    ],
    text: 'Dashboards',
  },
  flows: {
    icon: 'repeat',
    submenu: [
      {
        link: '/auth/multi-tenant-select',
        text: 'Multi-Tenant Registration',
      },
      {
        link: '/sb-dashboard/auth/wizard',
        text: 'Wizard',
        new: true,
      },
    ],
    text: 'Flows',
  },
  layout: {
    icon: 'layout',
    submenu: [
      {
        submenu: [
          {
            link: '/sb-dashboard/static',
            text: 'Static Navigation',
          },
          {
            link: '/sb-dashboard/dark',
            text: 'Dark Sidenav',
          },
          {
            link: '/sb-dashboard/rtl',
            text: 'RTL Layout',
          },
        ],
        text: 'Navigation',
      },
      {
        submenu: [
          {
            link: '/sb-dashboard/pages/layout-boxed',
            text: 'Boxed Layout',
            new: true,
          },
          {
            link: '/sb-dashboard/pages/layout-fluid',
            text: 'Fluid Layout',
            new: true,
          },
        ],
        text: 'Container Options',
      },
      {
        submenu: [
          {
            link: '/sb-dashboard/page-headers/simplified',
            text: 'Simplified',
          },
          {
            link: '/sb-dashboard/page-headers/compact',
            text: 'Compact',
            new: true,
          },
          {
            link: '/sb-dashboard/page-headers/content-overlap',
            text: 'Content Overlap',
          },
          {
            link: '/sb-dashboard/page-headers/breadcrumbs',
            text: 'Breadcrumbs',
          },
          {
            link: '/sb-dashboard/page-headers/light',
            text: 'Light',
          },
        ],
        text: 'Page Headers',
      },
      {
        submenu: [
          {
            link: '/sb-dashboard/pages/blank',
            text: 'Default',
          },
          {
            link: '/sb-dashboard/pages/starter-minimal',
            text: 'Minimal',
            new: true,
          },
        ],
        text: 'Starter Layouts',
      },
    ],
    text: 'Layout',
  },
  pages: {
    icon: 'grid',
    submenu: [
      {
        submenu: [
          {
            link: '/sb-dashboard/account/profile',
            text: 'Profile',
          },
          {
            link: '/sb-dashboard/account/billing',
            text: 'Billing',
          },
          {
            link: '/sb-dashboard/account/security',
            text: 'Security',
          },
          {
            link: '/sb-dashboard/account/notifications',
            text: 'Notifications',
          },
        ],
        text: 'Account',
        new: true,
      },
      {
        submenu: [
          {
            submenu: [
              {
                link: '/auth/login',
                text: 'Login',
              },
              {
                link: '/auth/register',
                text: 'Register',
              },
              {
                link: '/auth/forgot-password',
                text: 'Forgot Password',
              },
            ],
            text: 'Basic',
          },
          {
            submenu: [
              {
                link: '/auth/login-social',
                text: 'Login',
              },
              {
                link: '/auth/register-social',
                text: 'Register',
              },
              {
                link: '/auth/forgot-password-social',
                text: 'Forgot Password',
              },
            ],
            text: 'Social',
          },
        ],
        text: 'Authentication',
      },
      {
        submenu: [
          {
            link: '/error/400',
            text: '400 Error',
          },
          {
            link: '/error/401',
            text: '401 Error',
          },
          {
            link: '/error/403',
            text: '403 Error',
          },
          {
            link: '/error/404-1',
            text: '404 Error 1',
          },
          {
            link: '/error/404-2',
            text: '404 Error 2',
          },
          {
            link: '/error/500',
            text: '500 Page',
          },
          {
            link: '/error/503',
            text: '503 Page',
          },
          {
            link: '/error/504',
            text: '504 Page',
          },
        ],
        text: 'Error',
        updated: true,
      },
      {
        submenu: [
          {
            link: '/sb-dashboard/knowledge-base/home-1',
            text: 'Home 1',
          },
          {
            link: '/sb-dashboard/knowledge-base/home-2',
            text: 'Home 2',
          },
          {
            link: '/sb-dashboard/knowledge-base/category',
            text: 'Category',
          },
          {
            link: '/sb-dashboard/knowledge-base/article',
            text: 'Article',
          },
        ],
        text: 'Knowledge Base',
        new: true,
      },
      {
        link: '/sb-dashboard/shop/pricing',
        text: 'Pricing',
        new: true,
      },
      {
        link: '/sb-dashboard/shop/invoice',
        text: 'Invoice',
        new: true,
      },
    ],
    text: 'Pages',
  },
  tables: {
    icon: 'filter',
    link: '/tables',
    text: 'Tables',
  },
  utilities: {
    icon: 'tool',
    submenu: [
      {
        link: '/sb-dashboard/utilities/animations',
        text: 'Animations',
      },
      {
        link: '/sb-dashboard/utilities/background',
        text: 'Background',
      },
      {
        link: '/sb-dashboard/utilities/borders',
        text: 'Borders',
      },
      {
        link: '/sb-dashboard/utilities/lift',
        text: 'Lift',
      },
      {
        link: '/sb-dashboard/utilities/shadows',
        text: 'Shadows',
      },
      {
        link: '/sb-dashboard/utilities/typography',
        text: 'Typography',
      },
    ],
    text: 'Utilities',
  },
};
