import { Component } from '@angular/core';

@Component({
  selector: 'pg-layout-student-portal',
  templateUrl: './layout-student-portal.component.html',
  styleUrls: ['layout-student-portal.component.scss'],
})
export class LayoutStudentPortalComponent {
  constructor() {}
}
