import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardBase } from '@common/classes';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'pg-card-icon-link-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './card-icon-link-nav.component.html',
  styleUrls: ['card-icon-link-nav.component.scss'],
})
export class CardIconLinkNavComponent extends CardBase implements OnInit, OnDestroy {
  subs = new SubSink();
  iconBackground = 'bg-primary';
  textColor = 'text-primary';
  @Input() linkUrl = '/';
  @Input() color = 'primary';
  @Input() queryParams: { [key: string]: string } = {};
  @Input() active$: Observable<boolean> = of(false);
  @Input() tourAnchorTag = '';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.textColor = `text-${this.color}`;
    this.subs.sink = this.active$.subscribe((val) => {
      if (val) {
        this.iconBackground = `bg-${this.color}`;
      } else {
        this.iconBackground = `bg-${this.color}-soft`;
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
