import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

const uri = environment.API_URL;

@Component({
  selector: 'pg-layout-auth',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pg-layout-auth.component.html',
  styleUrls: ['pg-layout-auth.component.scss'],
})
export class PgLayoutAuthComponent implements OnInit {
  constructor(private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) {}

  isAPIError = false;
  @Input() showNav: boolean = true;

  ngOnInit() {
    this.http.get(`${uri.substring(0, uri.length - 7)}status`).subscribe(
      (result) => {},
      (err) => {
        this.isAPIError = true;
        this.changeDetectorRef.detectChanges();
        // display error connecting to API server
      }
    );
  }
}
