import { Component, computed, EventEmitter, input, Output } from '@angular/core';

@Component({
  selector: 'pg-table-icon-button',
  templateUrl: './table-icon-button.component.html',
  styleUrls: ['table-icon-button.component.scss'],
})
export class TableIconButtonComponent {
  tooltipText = input.required<string>();
  disabled = input<boolean>(false);
  @Output() clicked = new EventEmitter<void>();

  text = computed(() => {
    const isDisabled = this.disabled();
    const tooltip = this.tooltipText();
    if (isDisabled) {
      return 'External data source - cannot be altered';
    } else {
      return tooltip;
    }
  });
}
