<ng-container *ngIf="!rtl">
  <span [ngClass]='{ "nav-fixed": !static }'>
    <pg-top-nav></pg-top-nav>
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav"><pg-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems"
                                               [sideNavSections]="sideNavSections"></pg-side-nav></div>
      <div id="layoutSidenav_content" (click)="closeSideNavIfOpen()">
        <main>
          <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
        </main>
        <sb-footer></sb-footer>
      </div>
    </div>
  </span>
</ng-container>
<ng-container *ngIf="rtl"><span class="layout-rtl" [ngClass]='{ "nav-fixed": !static }'>
  <pg-top-nav></pg-top-nav>
    <div id="layoutSidenav">
      <div id="layoutSidenav_content" (click)="closeSideNavIfOpen()">
        <main><ng-container *ngTemplateOutlet="defaultContent"></ng-container></main>
        <sb-footer></sb-footer>
      </div>
      <div id="layoutSidenav_nav">
        <pg-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems"
                     [sideNavSections]="sideNavSections"></pg-side-nav>
      </div>
    </div>
</span>
</ng-container>
<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>
