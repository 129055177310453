import PerfectScrollbar from 'perfect-scrollbar';
import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Directive, Inject, Input, Output, NgModule } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

class MdbScrollbarDirective {
    _elementRef;
    _ngZone;
    platformId;
    config;
    scrollY = new EventEmitter();
    scrollX = new EventEmitter();
    scrollUp = new EventEmitter();
    scrollDown = new EventEmitter();
    scrollLeft = new EventEmitter();
    scrollRight = new EventEmitter();
    yReachEnd = new EventEmitter();
    yReachStart = new EventEmitter();
    xReachEnd = new EventEmitter();
    xReachStart = new EventEmitter();
    constructor(_elementRef, _ngZone, 
    // eslint-disable-next-line @typescript-eslint/ban-types
    platformId) {
        this._elementRef = _elementRef;
        this._ngZone = _ngZone;
        this.platformId = platformId;
    }
    _destroy = new Subject();
    _scrollbar = null;
    _events = [
        { ps: 'ps-scroll-y', mdb: 'scrollY' },
        { ps: 'ps-scroll-x', mdb: 'scrollX' },
        { ps: 'ps-scroll-up', mdb: 'scrollUp' },
        { ps: 'ps-scroll-down', mdb: 'scrollDown' },
        { ps: 'ps-scroll-left', mdb: 'scrollLeft' },
        { ps: 'ps-scroll-right', mdb: 'scrollRight' },
        { ps: 'ps-y-reach-end', mdb: 'yReachEnd' },
        { ps: 'ps-y-reach-start', mdb: 'yReachStart' },
        { ps: 'ps-x-reach-end', mdb: 'xReachEnd' },
        { ps: 'ps-x-reach-start', mdb: 'xReachStart' },
    ];
    _defaultConfig = {
        handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
        wheelSpeed: 1,
        wheelPropagation: true,
        swipeEasing: true,
        minScrollbarLength: null,
        maxScrollbarLength: null,
        scrollingThreshold: 1000,
        useBothWheelAxes: false,
        suppressScrollX: false,
        suppressScrollY: false,
        scrollXMarginOffset: 0,
        scrollYMarginOffset: 0,
    };
    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this._initScrollbar();
                this._bindEvents();
            }, 0);
        }
    }
    ngOnDestroy() {
        this._destroyScrollbar();
        this._destroy.next();
        this._destroy.complete();
    }
    _initScrollbar() {
        const config = this.config
            ? Object.assign(this._defaultConfig, this.config)
            : this._defaultConfig;
        this._ngZone.runOutsideAngular(() => {
            this._scrollbar = new PerfectScrollbar(this._elementRef.nativeElement, config);
        });
    }
    _bindEvents() {
        this._ngZone.runOutsideAngular(() => {
            this._events.forEach((eventName) => {
                fromEvent(this._elementRef.nativeElement, eventName.ps)
                    .pipe(takeUntil(this._destroy))
                    .subscribe((event) => {
                    this[eventName.mdb].emit(event);
                });
            });
        });
    }
    _destroyScrollbar() {
        this._ngZone.runOutsideAngular(() => {
            if (this._scrollbar) {
                this._scrollbar.destroy();
            }
        });
        this._scrollbar = null;
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarDirective, deps: [{ token: i0.ElementRef }, { token: i0.NgZone }, { token: PLATFORM_ID }], target: i0.ɵɵFactoryTarget.Directive });
    static ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "17.0.9", type: MdbScrollbarDirective, selector: "[mdbScrollbar]", inputs: { config: "config" }, outputs: { scrollY: "scrollY", scrollX: "scrollX", scrollUp: "scrollUp", scrollDown: "scrollDown", scrollLeft: "scrollLeft", scrollRight: "scrollRight", yReachEnd: "yReachEnd", yReachStart: "yReachStart", xReachEnd: "xReachEnd", xReachStart: "xReachStart" }, exportAs: ["mdbScrollbar"], ngImport: i0 });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarDirective, decorators: [{
            type: Directive,
            args: [{
                    // eslint-disable-next-line @angular-eslint/directive-selector
                    selector: '[mdbScrollbar]',
                    exportAs: 'mdbScrollbar',
                }]
        }], ctorParameters: () => [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: Object, decorators: [{
                    type: Inject,
                    args: [PLATFORM_ID]
                }] }], propDecorators: { config: [{
                type: Input
            }], scrollY: [{
                type: Output
            }], scrollX: [{
                type: Output
            }], scrollUp: [{
                type: Output
            }], scrollDown: [{
                type: Output
            }], scrollLeft: [{
                type: Output
            }], scrollRight: [{
                type: Output
            }], yReachEnd: [{
                type: Output
            }], yReachStart: [{
                type: Output
            }], xReachEnd: [{
                type: Output
            }], xReachStart: [{
                type: Output
            }] } });

class MdbScrollbarModule {
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarModule, declarations: [MdbScrollbarDirective], exports: [MdbScrollbarDirective] });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarModule });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbScrollbarModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [MdbScrollbarDirective],
                    exports: [MdbScrollbarDirective],
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { MdbScrollbarDirective, MdbScrollbarModule };

