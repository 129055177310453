<ng-container>
  <span class="nav-fixed">
    <pg-top-nav [sideToggle]="false"></pg-top-nav>
    <pg-layout-auth [showNav]="false">
    <main>
          <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
        </main>
      </pg-layout-auth>
  </span>
</ng-container>
<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>

