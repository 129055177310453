import { Injectable } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

@Injectable()
export class DateService {
  getDateFromNgbDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  setDateToNgbDate(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  dateToString(date: NgbDateStruct | null): string {
    if (date) {
      let dateString = `${date.year}-`;
      dateString += date.month < 10 ? String(date.month).padStart(2, '0') : `${date.month}`;
      dateString += '-';
      dateString += date.day < 10 ? String(date.day).padStart(2, '0') : `${date.day}`;
      return dateString;
    } else {
      return '';
    }
  }

  timeToString(time: NgbTimeStruct | null): string {
    if (time) {
      let dateString = '';
      dateString += time.hour < 10 ? String(time.hour).padStart(2, '0') : `${time.hour}`;
      dateString += ':';
      dateString += time.minute < 10 ? String(time.minute).padStart(2, '0') : `${time.minute}`;
      dateString += ':00';
      return dateString;
    } else {
      return '';
    }
  }

  dateTimeToString(date: NgbDateStruct, time: NgbTimeStruct): string {
    let dateString = this.dateToString(date);
    dateString += 'T';
    dateString += this.timeToString(time);
    return moment(dateString).toISOString();
  }

  momentToNgbDate(moment: moment.Moment): NgbDateStruct {
    return {
      year: moment.year(),
      month: moment.month() + 1,
      day: moment.date(),
    };
  }

  momentToNgbTime(moment: moment.Moment): NgbTimeStruct {
    return { hour: moment.hour(), minute: moment.minute(), second: 0 };
  }

  isBefore(startDate: Date, endDateTime: Date) {
    return moment(startDate).isBefore(endDateTime);
  }

  isAfter(startDate: Date, endDateTime: Date) {
    return moment(startDate).isAfter(endDateTime);
  }

  getDateTimeFromString(dateString: String) {}
}
