/* All feather icons -> https://github.com/michaelbazos/angular-feather#available-icons */

import {
  Activity,
  AlertTriangle,
  Archive,
  ArrowLeft,
  ArrowRight,
  ArrowRightCircle,
  ArrowUpLeft,
  Award,
  BarChart,
  Bell,
  Book,
  Bookmark,
  BookOpen,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  CloudRain,
  Code,
  Compass,
  Copy,
  CornerRightDown,
  CreditCard,
  Database,
  DollarSign,
  Download,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  Eye,
  Feather,
  File,
  FileText,
  Film,
  Filter,
  Flag,
  Gift,
  Globe,
  Grid,
  Heart,
  HelpCircle,
  Home,
  Info,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  List,
  Lock,
  LogOut,
  Mail,
  Map,
  Maximize,
  Menu,
  MessageCircle,
  MinusCircle,
  Moon,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Package,
  PieChart,
  PlusCircle,
  Repeat,
  Search,
  Send,
  Settings,
  Share,
  ShoppingCart,
  Sidebar,
  Sliders,
  Smartphone,
  Smile,
  Sun,
  Sunset,
  ThumbsDown,
  ThumbsUp,
  Tool,
  Trash2,
  TrendingDown,
  TrendingUp,
  Truck,
  Tv,
  Type,
  User,
  UserPlus,
  Users,
  X,
  XCircle,
  Zap,
  ZapOff,
  Plus,
} from 'angular-feather/icons';

export const featherIcons = {
  Activity,
  AlertTriangle,
  Archive,
  ArrowUpLeft,
  BarChart,
  Award,
  ArrowLeft,
  ArrowRight,
  ArrowRightCircle,
  Bell,
  Book,
  Bookmark,
  BookOpen,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronUp,
  ChevronDown,
  ChevronRight,
  Circle,
  Clock,
  Clipboard,
  CloudRain,
  Code,
  Compass,
  Copy,
  CornerRightDown,
  CreditCard,
  Database,
  DollarSign,
  Download,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  Eye,
  Feather,
  File,
  FileText,
  Film,
  Filter,
  Flag,
  Gift,
  Globe,
  Grid,
  Heart,
  HelpCircle,
  Home,
  Info,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  List,
  Lock,
  LogOut,
  Mail,
  Map,
  Maximize,
  Menu,
  MessageCircle,
  MinusCircle,
  Moon,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Package,
  PieChart,
  PlusCircle,
  Repeat,
  Search,
  Send,
  Settings,
  Share,
  ShoppingCart,
  Sidebar,
  Sliders,
  Smartphone,
  Smile,
  Sun,
  Sunset,
  Tool,
  ThumbsDown,
  ThumbsUp,
  Trash2,
  TrendingDown,
  TrendingUp,
  Truck,
  Tv,
  Type,
  User,
  UserPlus,
  Users,
  X,
  XCircle,
  Zap,
  ZapOff,
  Plus,
};
