import { __read, __values } from "tslib";
import { buffer, filter, map, debounceTime, merge, pairwise, delay } from 'rxjs';
import { filterOutNonTrackableEvents } from '../helpers';
import { AMPLITUDE_ELEMENT_CLICKED_EVENT } from '../constants';
var RAGE_CLICK_THRESHOLD = 5;
export function trackClicks(_a) {
    var amplitude = _a.amplitude, allObservables = _a.allObservables, options = _a.options, shouldTrackEvent = _a.shouldTrackEvent;
    var clickObservable = allObservables.clickObservable;
    // Trigger if the target of the click event has changed and position is different
    // Keeping track of position is important to avoid false positives when the user clicks on the same
    // element where certain frameworks could replace the element instance between rerenders
    var comparisonTrigger = clickObservable.pipe(pairwise(), filter(function (_a) {
        var _b = __read(_a, 2), prev = _b[0], current = _b[1];
        var targetChanged = prev.event.target !== current.event.target;
        /* istanbul ignore next */
        var samePos = Math.abs(current.event.screenX - prev.event.screenX) <= 20 &&
            Math.abs(current.event.screenY - prev.event.screenY) <= 20;
        return targetChanged && !samePos;
    }));
    // Trigger if there is no click event within 1 second
    var timeoutTrigger = clickObservable.pipe(debounceTime(options.debounceTime), map(function () { return 'timeout'; }));
    var triggers = merge(comparisonTrigger, timeoutTrigger);
    // Get buffers of clicks, if the buffer length is over 5, it is rage click
    var bufferedClicks = clickObservable.pipe(delay(0), filter(filterOutNonTrackableEvents), filter(function (click) {
        // Only track clicks on elements that should be tracked,
        return shouldTrackEvent('click', click.closestTrackedAncestor);
    }), buffer(triggers));
    return bufferedClicks.subscribe(function (clicks) {
        var e_1, _a;
        // TODO: update this when rage clicks are added
        var clickType = clicks.length >= RAGE_CLICK_THRESHOLD ? AMPLITUDE_ELEMENT_CLICKED_EVENT : AMPLITUDE_ELEMENT_CLICKED_EVENT;
        try {
            for (var clicks_1 = __values(clicks), clicks_1_1 = clicks_1.next(); !clicks_1_1.done; clicks_1_1 = clicks_1.next()) {
                var click = clicks_1_1.value;
                /* istanbul ignore next */
                amplitude === null || amplitude === void 0 ? void 0 : amplitude.track(clickType, click.targetElementProperties, {
                    time: click.timestamp,
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (clicks_1_1 && !clicks_1_1.done && (_a = clicks_1.return)) _a.call(clicks_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
}
