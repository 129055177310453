import { SideNavItems, SideNavSection } from '../models';

export const sideNavSections: SideNavSection[] = [
  {
    text: 'DASHBOARD',
    items: ['dashboard', 'groups', 'districts', 'students', 'classrooms', 'users', 'sales', 'account'],
  },
];

export const sideNavItems: SideNavItems = {
  dashboard: {
    faIcon: 'user-secret',
    text: 'Admin Portal',
    link: '/admin',
  },
  groups: {
    text: 'Groups',
    faIcon: 'layer-group',
    link: '/admin/groups',
  },
  districts: {
    text: 'District Admins',
    faIcon: 'user-crown',
    link: '/admin/districts',
  },
  teachers: {
    text: 'Teachers',
    faIcon: 'chalkboard-teacher',
    link: '/admin/teachers',
  },
  students: {
    text: 'Students',
    faIcon: 'user-graduate',
    link: '/admin/students',
  },
  classrooms: {
    text: 'Classrooms',
    faIcon: 'chalkboard',
    link: '/admin/classrooms',
  },
  users: {
    text: 'All Users',
    faIcon: 'users',
    link: '/admin/users',
  },
  sales: {
    text: 'Sales Team',
    faIcon: 'comments-dollar',
    link: '/admin/sales/home',
    submenu:[

    ]
  },
  account: {
    text: 'Account',
    faIcon: 'user-cog',
    submenu: [
      {
        link: '/admin/account/profile',
        text: 'Edit Profile',
      },
    ],
  },
};
