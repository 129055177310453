import { Directive, ElementRef, OnInit, inject, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { DataCyService } from '@common/services/data-cy.service';

@Directive({
  selector: '[appDataCy]',
})
export class DataCyDirective implements OnInit {
  el = inject(ElementRef);
  dataCyService = inject(DataCyService);
  route = inject(ActivatedRoute);

  routeData = toSignal(this.route.data);
  cyData = computed(() => this.routeData()?.cy);

  constructor() {}

  ngOnInit(): void {
    let tagName = this.el.nativeElement.tagName?.toLowerCase();

    const dataCyValue = this.dataCyService.generateDataCy(this.cyData(), tagName);
    this.el.nativeElement.setAttribute('data-cy', dataCyValue);
  }
}
