import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataCyService {
  counters = signal<{ [key: string]: number }>({});

  generateDataCy(componentName: string, componentType: string): string {
    const key = `${componentName}-${componentType}`;
    if (!this.counters()[key]) {
      this.counters()[key] = 0;
    }
    const count = this.counters()[key]++;
    return `${componentName}-${componentType}_${count}`;
  }
}
