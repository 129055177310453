import * as i0 from '@angular/core';
import { InjectionToken, NgZone, Injector, Injectable, NgModule } from '@angular/core';
import * as i1 from '@ngxs/store';
import { Action, Selector, State, Store, NgxsModule } from '@ngxs/store';
import { __decorate, __metadata } from 'tslib';
import * as i2 from '@angular/router';
import { NavigationStart, RoutesRecognized, ResolveEnd, NavigationCancel, NavigationError, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Public event api of the router
 */
class Navigate {
    constructor(path, queryParams, extras) {
        this.path = path;
        this.queryParams = queryParams;
        this.extras = extras;
    }
}
Navigate.type = '[Router] Navigate';
/**
 *
 * Angular Routers internal state events
 *
 */
/**
 * An action dispatched when the router starts the navigation.
 */
class RouterRequest {
    constructor(routerState, event, trigger = 'none') {
        this.routerState = routerState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterRequest.type = '[Router] RouterRequest';
/**
 * An action dispatched when the router navigates.
 */
class RouterNavigation {
    constructor(routerState, event, trigger = 'none') {
        this.routerState = routerState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterNavigation.type = '[Router] RouterNavigation';
/**
 * An action dispatched when the router cancel navigation.
 */
class RouterCancel {
    constructor(routerState, storeState, event, trigger = 'none') {
        this.routerState = routerState;
        this.storeState = storeState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterCancel.type = '[Router] RouterCancel';
/**
 * An action dispatched when the router errors.
 */
class RouterError {
    constructor(routerState, storeState, event, trigger = 'none') {
        this.routerState = routerState;
        this.storeState = storeState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterError.type = '[Router] RouterError';
/**
 * An action dispatched when the `ResolveEnd` event is triggered.
 */
class RouterDataResolved {
    constructor(routerState, event, trigger = 'none') {
        this.routerState = routerState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterDataResolved.type = '[Router] RouterDataResolved';
/**
 * An action dispatched when the router navigation has been finished successfully.
 */
class RouterNavigated {
    constructor(routerState, event, trigger = 'none') {
        this.routerState = routerState;
        this.event = event;
        this.trigger = trigger;
    }
}
RouterNavigated.type = '[Router] RouterNavigated';

class RouterStateSerializer {
}
class DefaultRouterStateSerializer {
    serialize(routerState) {
        return {
            root: this.serializeRoute(routerState.root),
            url: routerState.url
        };
    }
    serializeRoute(route) {
        const children = route.children.map(c => this.serializeRoute(c));
        return {
            url: route.url,
            params: route.params,
            queryParams: route.queryParams,
            fragment: route.fragment,
            data: route.data,
            outlet: route.outlet,
            component: null,
            routeConfig: null,
            root: null,
            parent: null,
            firstChild: children[0],
            children: children,
            pathFromRoot: null,
            paramMap: route.paramMap,
            queryParamMap: route.queryParamMap,
            toString: route.toString
        };
    }
}

const USER_OPTIONS = new InjectionToken('USER_OPTIONS', { providedIn: 'root', factory: () => undefined });
const NGXS_ROUTER_PLUGIN_OPTIONS = new InjectionToken('NGXS_ROUTER_PLUGIN_OPTIONS', { providedIn: 'root', factory: () => ({}) });
function createRouterPluginOptions(options) {
    return {
        navigationActionTiming: (options && options.navigationActionTiming) || 1 /* PreActivation */
    };
}

var RouterState_1;
let RouterState = RouterState_1 = class RouterState {
    constructor(_store, _router, _serializer, _ngZone, injector) {
        this._store = _store;
        this._router = _router;
        this._serializer = _serializer;
        this._ngZone = _ngZone;
        /**
         * Determines how navigation was performed by the `RouterState` itself
         * or outside via `new Navigate(...)`
         */
        this._trigger = 'none';
        /**
         * That's the serialized state from the `Router` class
         */
        this._routerState = null;
        /**
         * That's the value of the `RouterState` state
         */
        this._storeState = null;
        this._lastEvent = null;
        this._subscription = new Subscription();
        this._options = null;
        // Note: do not use `@Inject` since it fails on lower versions of Angular with Jest
        // integration, it cannot resolve the token provider.
        this._options = injector.get(NGXS_ROUTER_PLUGIN_OPTIONS, null);
        this._setUpStoreListener();
        this._setUpRouterEventsListener();
    }
    static state(state) {
        return state && state.state;
    }
    static url(state) {
        return state && state.state && state.state.url;
    }
    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
    navigate(_, action) {
        return this._ngZone.run(() => this._router.navigate(action.path, Object.assign({ queryParams: action.queryParams }, action.extras)));
    }
    angularRouterAction(ctx, action) {
        ctx.setState({
            trigger: action.trigger,
            state: action.routerState,
            navigationId: action.event.id
        });
    }
    _setUpStoreListener() {
        const subscription = this._store
            .select(RouterState_1)
            .subscribe((state) => {
            this._navigateIfNeeded(state);
        });
        this._subscription.add(subscription);
    }
    _navigateIfNeeded(routerState) {
        if (routerState && routerState.trigger === 'devtools') {
            this._storeState = this._store.selectSnapshot(RouterState_1);
        }
        const canSkipNavigation = !this._storeState ||
            !this._storeState.state ||
            !routerState ||
            routerState.trigger === 'router' ||
            this._router.url === this._storeState.state.url ||
            this._lastEvent instanceof NavigationStart;
        if (canSkipNavigation) {
            return;
        }
        this._storeState = this._store.selectSnapshot(RouterState_1);
        this._trigger = 'store';
        this._ngZone.run(() => this._router.navigateByUrl(this._storeState.state.url));
    }
    _setUpRouterEventsListener() {
        const dispatchRouterNavigationLate = this._options != null &&
            this._options.navigationActionTiming === 2 /* PostActivation */;
        let lastRoutesRecognized;
        const subscription = this._router.events.subscribe(event => {
            this._lastEvent = event;
            if (event instanceof NavigationStart) {
                this._navigationStart(event);
            }
            else if (event instanceof RoutesRecognized) {
                lastRoutesRecognized = event;
                if (!dispatchRouterNavigationLate && this._trigger !== 'store') {
                    this._dispatchRouterNavigation(lastRoutesRecognized);
                }
            }
            else if (event instanceof ResolveEnd) {
                this._dispatchRouterDataResolved(event);
            }
            else if (event instanceof NavigationCancel) {
                this._dispatchRouterCancel(event);
                this._reset();
            }
            else if (event instanceof NavigationError) {
                this._dispatchRouterError(event);
                this._reset();
            }
            else if (event instanceof NavigationEnd) {
                if (this._trigger !== 'store') {
                    if (dispatchRouterNavigationLate) {
                        this._dispatchRouterNavigation(lastRoutesRecognized);
                    }
                    this._dispatchRouterNavigated(event);
                }
                this._reset();
            }
        });
        this._subscription.add(subscription);
    }
    /** Reacts to `NavigationStart`. */
    _navigationStart(event) {
        this._routerState = this._serializer.serialize(this._router.routerState.snapshot);
        if (this._trigger !== 'none') {
            this._storeState = this._store.selectSnapshot(RouterState_1);
            this._dispatchRouterAction(new RouterRequest(this._routerState, event, this._trigger));
        }
    }
    /** Reacts to `ResolveEnd`. */
    _dispatchRouterDataResolved(event) {
        const routerState = this._serializer.serialize(event.state);
        this._dispatchRouterAction(new RouterDataResolved(routerState, event, this._trigger));
    }
    /** Reacts to `RoutesRecognized` or `NavigationEnd`, depends on the `navigationActionTiming`. */
    _dispatchRouterNavigation(lastRoutesRecognized) {
        const nextRouterState = this._serializer.serialize(lastRoutesRecognized.state);
        this._dispatchRouterAction(new RouterNavigation(nextRouterState, new RoutesRecognized(lastRoutesRecognized.id, lastRoutesRecognized.url, lastRoutesRecognized.urlAfterRedirects, nextRouterState), this._trigger));
    }
    /** Reacts to `NavigationCancel`. */
    _dispatchRouterCancel(event) {
        this._dispatchRouterAction(new RouterCancel(this._routerState, this._storeState, event, this._trigger));
    }
    /** Reacts to `NavigationEnd`. */
    _dispatchRouterError(event) {
        this._dispatchRouterAction(new RouterError(this._routerState, this._storeState, new NavigationError(event.id, event.url, `${event}`), this._trigger));
    }
    /** Reacts to `NavigationEnd`. */
    _dispatchRouterNavigated(event) {
        const routerState = this._serializer.serialize(this._router.routerState.snapshot);
        this._dispatchRouterAction(new RouterNavigated(routerState, event, this._trigger));
    }
    _dispatchRouterAction(action) {
        this._trigger = 'router';
        try {
            this._store.dispatch(action);
        }
        finally {
            this._trigger = 'none';
        }
    }
    _reset() {
        this._trigger = 'none';
        this._storeState = null;
        this._routerState = null;
    }
};
/** @nocollapse */ RouterState.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: RouterState, deps: [{ token: i1.Store }, { token: i2.Router }, { token: RouterStateSerializer }, { token: i0.NgZone }, { token: i0.Injector }], target: i0.ɵɵFactoryTarget.Injectable });
/** @nocollapse */ RouterState.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: RouterState });
__decorate([
    Action(Navigate),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Navigate]),
    __metadata("design:returntype", void 0)
], RouterState.prototype, "navigate", null);
__decorate([
    Action([
        RouterRequest,
        RouterNavigation,
        RouterError,
        RouterCancel,
        RouterDataResolved,
        RouterNavigated
    ]),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RouterState.prototype, "angularRouterAction", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], RouterState, "state", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], RouterState, "url", null);
RouterState = RouterState_1 = __decorate([
    State({
        name: 'router',
        defaults: {
            state: undefined,
            navigationId: undefined,
            trigger: 'none'
        }
    }),
    __metadata("design:paramtypes", [Store,
        Router,
        RouterStateSerializer,
        NgZone,
        Injector])
], RouterState);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: RouterState, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: i1.Store }, { type: i2.Router }, { type: RouterStateSerializer }, { type: i0.NgZone }, { type: i0.Injector }]; }, propDecorators: { navigate: [], angularRouterAction: [] } });

class NgxsRouterPluginModule {
    static forRoot(options) {
        return {
            ngModule: NgxsRouterPluginModule,
            providers: [
                { provide: USER_OPTIONS, useValue: options },
                {
                    provide: NGXS_ROUTER_PLUGIN_OPTIONS,
                    useFactory: createRouterPluginOptions,
                    deps: [USER_OPTIONS]
                },
                { provide: RouterStateSerializer, useClass: DefaultRouterStateSerializer }
            ]
        };
    }
}
/** @nocollapse */ NgxsRouterPluginModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsRouterPluginModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
/** @nocollapse */ NgxsRouterPluginModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsRouterPluginModule, imports: [i1.ɵNgxsFeatureModule] });
/** @nocollapse */ NgxsRouterPluginModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsRouterPluginModule, imports: [[NgxsModule.forFeature([RouterState])]] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsRouterPluginModule, decorators: [{
            type: NgModule,
            args: [{
                    imports: [NgxsModule.forFeature([RouterState])]
                }]
        }] });

/**
 * The public api for consumers of @ngxs/router-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DefaultRouterStateSerializer, Navigate, NgxsRouterPluginModule, RouterCancel, RouterDataResolved, RouterError, RouterNavigated, RouterNavigation, RouterRequest, RouterState, RouterStateSerializer };

