import { Injectable } from '@angular/core';
import { Characters as CharactersProfilePictures } from '@modules/auth/enum/characters';

export namespace PlasmaGames {
  export type ProfilePicturePath = CharactersProfilePictures | string;

  export interface Character {
    id: string,
    name: string,
    profilePicture: ProfilePicturePath,
    profilePictureSrc: string
  }
}

const Inaya: Partial<PlasmaGames.Character> = { name: 'Inaya', id: 'char_01' };
const Keyshawn: Partial<PlasmaGames.Character> = { name: 'Keyshawn', id: 'char_02' };
const Bridgett: Partial<PlasmaGames.Character> = { name: 'Bridgett', id: 'char_03' };
const Coop: Partial<PlasmaGames.Character> = { name: 'Coop', id: 'char_04' };
const Noe: Partial<PlasmaGames.Character> = { name: 'Noe', id: 'char_05' };
const Ashanti: Partial<PlasmaGames.Character> = { name: 'Ashanti', id: 'char_06' };
const Eliza: Partial<PlasmaGames.Character> = { name: 'Eliza', id: 'char_07' };
const Marco: Partial<PlasmaGames.Character> = { name: 'Marco', id: 'char_08' };
const Logan: Partial<PlasmaGames.Character> = { name: 'Logan', id: 'char_09' };
const Captain: Partial<PlasmaGames.Character> = { name: 'Captain', id: 'char_10' };
const Rayna: Partial<PlasmaGames.Character> = { name: 'Rayna', id: 'char_11' };
const Aiden: Partial<PlasmaGames.Character> = { name: 'Aiden', id: 'char_12' };
const Saffron: Partial<PlasmaGames.Character> = { name: 'Saffron', id: 'char_13' };
const Owen: Partial<PlasmaGames.Character> = { name: 'Owen', id: 'char_14' };
const Zane: Partial<PlasmaGames.Character> = { name: 'Zane', id: 'char_15' };
const Hazel: Partial<PlasmaGames.Character> = { name: 'Hazel', id: 'char_16' };
const Vera: Partial<PlasmaGames.Character> = { name: 'Vera', id: 'char_17' };
const Lobber: Partial<PlasmaGames.Character> = { name: 'Lobber', id: 'char_18' };

const AllCharacters: PlasmaGames.Character[] = [Inaya, Keyshawn, Bridgett, Coop, Noe, Ashanti, Eliza, Marco, Logan, Captain, Rayna, Aiden, Saffron, Owen, Zane, Hazel, Vera, Lobber].map(
  character => ({
    ...character,
    profilePicture: character.id,
    profilePictureSrc: `assets/img/student-portal/character-select/character-portraits/${character.id}.png`,
    // wholeBodyImageSrc: `assets/img/characters/full/${character.id}.png`,
  } as PlasmaGames.Character),
);

@Injectable({
  providedIn: 'root',
})
export class CharactersService {

  public getAllCharacters(): PlasmaGames.Character[] {
    return AllCharacters;
  }

  public getAllProfilePictures(): string[] {
    return AllCharacters.map(c => c.profilePicture);
  }
}
