import { FooterComponent } from './footer/footer.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { PgSideNavComponent } from './pg-side-nav/pg-side-nav.component';
import { PgTopNavComponent } from './pg-top-nav/pg-top-nav.component';

export const containers = [TopNavComponent, SideNavComponent, FooterComponent, PgTopNavComponent, PgSideNavComponent];

export * from './top-nav/top-nav.component';
export * from './side-nav/side-nav.component';
export * from './footer/footer.component';
export * from './pg-side-nav/pg-side-nav.component';
export * from './pg-top-nav/pg-top-nav.component';
