import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  protected readonly location: Location = inject(Location);

  constructor() {}

  public goBack() {
    this.location.back();
  }
}
