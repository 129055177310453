import { Component, Input, OnInit } from '@angular/core';
import { ClassroomColor } from 'generated/graphql';

@Component({
  selector: 'pg-dashboard-head',
  templateUrl: './pg-dashboard-head.component.html',
  styleUrls: ['pg-dashboard-head.component.scss'],
})
export class PgDashboardHeadComponent implements OnInit {
  @Input() icon?: string;
  @Input() faIcon?: string;
  @Input() faIconStyle = 'fad';
  @Input() title!: string;
  @Input() description!: string;
  @Input() simplified = false;
  @Input() color = ClassroomColor.Blue;
  @Input() breadcrumbs = false;
  @Input() tourAnchorTag = '';
  @Input() headerBadge = false;
  backgroundColor = 'bg-gradient-pg-blue';

  constructor() {}
  ngOnInit() {
    switch (this.color) {
      case ClassroomColor.Blue:
        this.backgroundColor = 'bg-gradient-pg-blue';
        break;
      case ClassroomColor.Green:
        this.backgroundColor = 'bg-gradient-pg-green';
        break;
      case ClassroomColor.Indigo:
        this.backgroundColor = 'bg-gradient-pg-indigo';
        break;
      case ClassroomColor.Maroon:
        this.backgroundColor = 'bg-gradient-pg-maroon';
        break;
      case ClassroomColor.Orange:
        this.backgroundColor = 'bg-gradient-pg-orange';
        break;
      case ClassroomColor.Purple:
        this.backgroundColor = 'bg-gradient-pg-purple';
        break;
      case ClassroomColor.Red:
        this.backgroundColor = 'bg-gradient-pg-red';
        break;
      case ClassroomColor.Yellow:
        this.backgroundColor = 'bg-gradient-pg-yellow';
        break;
    }
  }
}
