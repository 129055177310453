import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoggedInUser } from '@modules/auth/store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ComparePasswordGQL, GetUserDto, UpdateUserPasswordGQL } from 'generated/graphql';
import { map } from 'rxjs/operators';
import { ToastService } from '@common/services';

@Component({
  selector: 'pg-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  changePasswordForm: UntypedFormGroup;

  user: LoggedInUser;

  @ViewChild('successHeader') successHeader: TemplateRef<any>;

  constructor(private store: Store, private comparePasswordGQL: ComparePasswordGQL, private updateUserPasswordGQL: UpdateUserPasswordGQL, private toastService: ToastService) {
    this.changePasswordForm = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)], [this.comparePasswordMatch.bind(this)]),
        newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
        confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      },
      {
        validators: [this.checkPassMatchConfirm, this.checkOldPassMatchNew],
      }
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  ngOnInit() {
    this.user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser.user);
  }

  comparePasswordMatch(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.comparePasswordGQL.fetch({ getUser: { _id: this.user._id }, password: control.value }).pipe(
      map(({ data }) => {
        return data.compareUserPassword ? null : { isNotMatch: true };
      })
    );
  }

  checkPassMatchConfirm: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('newPassword') as UntypedFormControl;
    const confirmPassword = control.get('confirmPassword') as UntypedFormControl;
    return password && confirmPassword && password.value === confirmPassword.value ? null : { passwordMismatch: true };
  };

  checkOldPassMatchNew: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const oldPassword = control.get('oldPassword') as UntypedFormControl;
    const newPassword = control.get('newPassword') as UntypedFormControl;
    return oldPassword && newPassword && oldPassword.value === newPassword.value ? { oldMatchNew: true } : null;
  };

  submitPassword() {
    const newPassword = this.newPasswordControl.value;
    const getUser: GetUserDto = { _id: this.user._id };
    this.subs.sink = this.updateUserPasswordGQL.mutate({ getUser, newPassword }).subscribe(({ data }) => {
      this.oldPasswordControl.reset();
      this.newPasswordControl.reset();
      this.confirmPasswordControl.reset();
      this.showSuccessToast();
    });
  }

  showSuccessToast() {
    this.toastService.show(this.successHeader, '', {
      autohide: true,
      delay: 7500,
      headerClasses: 'bg-success text-white',
      bodyClasses: 'd-none',
    });
  }

  get oldPasswordControl() {
    return this.changePasswordForm.get('oldPassword') as UntypedFormControl;
  }
  get oldPasswordControlIsPending() {
    return this.oldPasswordControl.pending;
  }
  get oldPasswordControlValid() {
    return this.oldPasswordControl.touched && !this.oldPasswordControlInvalid && !this.oldPasswordControlIsPending;
  }
  get oldPasswordControlInvalid() {
    return (
      this.oldPasswordControl.touched &&
      (this.oldPasswordControl.hasError('required') || this.oldPasswordControl.hasError('minlength') || this.oldPasswordControl.hasError('isNotMatch'))
    );
  }

  get newPasswordControl() {
    return this.changePasswordForm.get('newPassword') as UntypedFormControl;
  }
  get newPasswordControlValid() {
    return this.newPasswordControl.touched && !this.newPasswordControlInvalid;
  }
  get newPasswordControlInvalid() {
    return (
      this.newPasswordControl.touched &&
      (this.newPasswordControl.hasError('required') || this.newPasswordControl.hasError('minlength') || this.changePasswordForm.hasError('oldMatchNew'))
    );
  }

  get confirmPasswordControl() {
    return this.changePasswordForm.get('confirmPassword') as UntypedFormControl;
  }
  get confirmPasswordControlValid() {
    return this.confirmPasswordControl.touched && !this.confirmPasswordControlInvalid;
  }
  get confirmPasswordControlInvalid() {
    return (
      this.confirmPasswordControl.touched &&
      (this.confirmPasswordControl.hasError('required') || this.confirmPasswordControl.hasError('minlength') || this.changePasswordForm.hasError('passwordMismatch'))
    );
  }
}
