import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SendEmailForgotPasswordGQL, SendEmailForgotPasswordMutation } from '../../../generated/graphql';
import { MutationResult } from 'apollo-angular';


@Injectable()
export class ForgotPasswordService {
  private readonly sendEmailForgotPasswordGQL = inject(SendEmailForgotPasswordGQL);
  constructor() {}

  sendEmailForgotPassword(email: string): Observable<MutationResult<SendEmailForgotPasswordMutation>> {
    return this.sendEmailForgotPasswordGQL.mutate({ email }, { errorPolicy: 'all' });
  }
}
