<ng-container>
  <nav class="topnav navbar navbar-expand shadow navbar-light bg-white px-3">
    <a class="navbar-brand" [routerLink]="route">
      <img style="height: 40px" src="https://dkmzd4tgwtjws.cloudfront.net/public/images/logos/plasma_logo_h.png" alt="Plasma Games logo" />
    </a>
    <!--    <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-lg-2" id="sidebarToggle" *ngIf="sideToggle" data-cy="topNavToggleSideNav" (click)="toggleSideNav()">-->
    <!--      <i-feather name="menu"></i-feather>-->
    <!--    </button>-->
    <div class="container-fluid">
      <ul class="navbar-nav align-items-center ms-auto">
        <pg-notifications-alert *ngIf="role === 'STUDENT'"></pg-notifications-alert>
        <sbpro-top-nav-user tourAnchor="user-icon"></sbpro-top-nav-user>
      </ul>
    </div>
  </nav>
</ng-container>
