import { UpdatePortalNotificationDto } from '../../../generated/graphql';

export class LoadNotificationsData {
  static readonly type = '[Notifications] Load Notifications Data';
}

export class CheckForNewNotificationsData {
  static readonly type = '[Notifications] Check for New Notifications Data';

  constructor(public payload: { user: string }) {}
}

export class UpdateNotificationRead {
  static readonly type = '[Notifications] Update Notification to Read';

  constructor(public payload: { notificationId: string; updateNotificationDto: UpdatePortalNotificationDto }) {}
}

export class UpdateNotificationDeleted {
  static readonly type = '[Notifications] Update Notification to Deleted';

  constructor(public payload: { notificationId: string }) {}
}

