import { __assign, __awaiter, __extends, __generator } from "tslib";
import { AmplitudeCore, Destination, Identify, returnWrapper, Revenue, UUID } from '@amplitude/analytics-core';
import { getAnalyticsConnector, getAttributionTrackingConfig, getPageViewTrackingConfig, getElementInteractionsConfig, IdentityEventSender, isAttributionTrackingEnabled, isSessionTrackingEnabled, isFileDownloadTrackingEnabled, isFormInteractionTrackingEnabled, isElementInteractionsEnabled, setConnectorDeviceId, setConnectorUserId, isNewSession, isPageViewTrackingEnabled, WebAttribution, getQueryParams, } from '@amplitude/analytics-client-common';
import { OfflineDisabled, } from '@amplitude/analytics-types';
import { convertProxyObjectToRealObject, isInstanceProxy } from './utils/snippet-helper';
import { Context } from './plugins/context';
import { useBrowserConfig, createTransport } from './config';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';
import { formInteractionTracking } from './plugins/form-interaction-tracking';
import { fileDownloadTracking } from './plugins/file-download-tracking';
import { DEFAULT_SESSION_END_EVENT, DEFAULT_SESSION_START_EVENT } from './constants';
import { detNotify } from './det-notification';
import { networkConnectivityCheckerPlugin } from './plugins/network-connectivity-checker';
import { createBrowserJoinedConfigGenerator } from './config/joined-config';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
var AmplitudeBrowser = /** @class */ (function (_super) {
    __extends(AmplitudeBrowser, _super);
    function AmplitudeBrowser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AmplitudeBrowser.prototype.init = function (apiKey, userIdOrOptions, maybeOptions) {
        if (apiKey === void 0) { apiKey = ''; }
        var userId;
        var options;
        if (arguments.length > 2) {
            userId = userIdOrOptions;
            options = maybeOptions;
        }
        else {
            if (typeof userIdOrOptions === 'string') {
                userId = userIdOrOptions;
                options = undefined;
            }
            else {
                userId = userIdOrOptions === null || userIdOrOptions === void 0 ? void 0 : userIdOrOptions.userId;
                options = userIdOrOptions;
            }
        }
        return returnWrapper(this._init(__assign(__assign({}, options), { userId: userId, apiKey: apiKey })));
    };
    AmplitudeBrowser.prototype._init = function (options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var browserOptions, joinedConfigGenerator, attributionTrackingOptions, queryParams, querySessionId, connector;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        // Step 1: Block concurrent initialization
                        if (this.initializing) {
                            return [2 /*return*/];
                        }
                        this.initializing = true;
                        return [4 /*yield*/, useBrowserConfig(options.apiKey, options, this)];
                    case 1:
                        browserOptions = _d.sent();
                        if (!options.fetchRemoteConfig) return [3 /*break*/, 4];
                        return [4 /*yield*/, createBrowserJoinedConfigGenerator(browserOptions)];
                    case 2:
                        joinedConfigGenerator = _d.sent();
                        return [4 /*yield*/, joinedConfigGenerator.generateJoinedConfig()];
                    case 3:
                        browserOptions = _d.sent();
                        _d.label = 4;
                    case 4: return [4 /*yield*/, _super.prototype._init.call(this, browserOptions)];
                    case 5:
                        _d.sent();
                        this.logBrowserOptions(browserOptions);
                        if (!isAttributionTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 7];
                        attributionTrackingOptions = getAttributionTrackingConfig(this.config);
                        this.webAttribution = new WebAttribution(attributionTrackingOptions, this.config);
                        // Fetch the current campaign, check if need to track web attribution later
                        return [4 /*yield*/, this.webAttribution.init()];
                    case 6:
                        // Fetch the current campaign, check if need to track web attribution later
                        _d.sent();
                        _d.label = 7;
                    case 7:
                        queryParams = getQueryParams();
                        querySessionId = Number.isNaN(Number(queryParams.ampSessionId))
                            ? undefined
                            : Number(queryParams.ampSessionId);
                        this.setSessionId((_c = (_b = (_a = options.sessionId) !== null && _a !== void 0 ? _a : querySessionId) !== null && _b !== void 0 ? _b : this.config.sessionId) !== null && _c !== void 0 ? _c : Date.now());
                        connector = getAnalyticsConnector(options.instanceName);
                        connector.identityStore.setIdentity({
                            userId: this.config.userId,
                            deviceId: this.config.deviceId,
                        });
                        if (!(this.config.offline !== OfflineDisabled)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.add(networkConnectivityCheckerPlugin()).promise];
                    case 8:
                        _d.sent();
                        _d.label = 9;
                    case 9: return [4 /*yield*/, this.add(new Destination()).promise];
                    case 10:
                        _d.sent();
                        return [4 /*yield*/, this.add(new Context()).promise];
                    case 11:
                        _d.sent();
                        return [4 /*yield*/, this.add(new IdentityEventSender()).promise];
                    case 12:
                        _d.sent();
                        // Notify if DET is enabled
                        detNotify(this.config);
                        if (!isFileDownloadTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 14];
                        this.config.loggerProvider.debug('Adding file download tracking plugin');
                        return [4 /*yield*/, this.add(fileDownloadTracking()).promise];
                    case 13:
                        _d.sent();
                        _d.label = 14;
                    case 14:
                        if (!isFormInteractionTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 16];
                        this.config.loggerProvider.debug('Adding form interaction plugin');
                        return [4 /*yield*/, this.add(formInteractionTracking()).promise];
                    case 15:
                        _d.sent();
                        _d.label = 16;
                    case 16:
                        if (!isPageViewTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 18];
                        this.config.loggerProvider.debug('Adding page view tracking plugin');
                        return [4 /*yield*/, this.add(pageViewTrackingPlugin(getPageViewTrackingConfig(this.config))).promise];
                    case 17:
                        _d.sent();
                        _d.label = 18;
                    case 18:
                        if (!isElementInteractionsEnabled(this.config.autocapture)) return [3 /*break*/, 20];
                        this.config.loggerProvider.debug('Adding user interactions plugin (autocapture plugin)');
                        return [4 /*yield*/, this.add(autocapturePlugin(getElementInteractionsConfig(this.config))).promise];
                    case 19:
                        _d.sent();
                        _d.label = 20;
                    case 20:
                        this.initializing = false;
                        // Step 6: Run queued dispatch functions
                        return [4 /*yield*/, this.runQueuedFunctions('dispatchQ')];
                    case 21:
                        // Step 6: Run queued dispatch functions
                        _d.sent();
                        // Step 7: Add the event receiver after running remaining queued functions.
                        connector.eventBridge.setEventReceiver(function (event) {
                            void _this.track(event.eventType, event.eventProperties);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AmplitudeBrowser.prototype.getUserId = function () {
        var _a;
        return (_a = this.config) === null || _a === void 0 ? void 0 : _a.userId;
    };
    AmplitudeBrowser.prototype.setUserId = function (userId) {
        if (!this.config) {
            this.q.push(this.setUserId.bind(this, userId));
            return;
        }
        this.config.loggerProvider.debug('function setUserId: ', userId);
        if (userId !== this.config.userId || userId === undefined) {
            this.config.userId = userId;
            setConnectorUserId(userId, this.config.instanceName);
        }
    };
    AmplitudeBrowser.prototype.getDeviceId = function () {
        var _a;
        return (_a = this.config) === null || _a === void 0 ? void 0 : _a.deviceId;
    };
    AmplitudeBrowser.prototype.setDeviceId = function (deviceId) {
        if (!this.config) {
            this.q.push(this.setDeviceId.bind(this, deviceId));
            return;
        }
        this.config.loggerProvider.debug('function setDeviceId: ', deviceId);
        this.config.deviceId = deviceId;
        setConnectorDeviceId(deviceId, this.config.instanceName);
    };
    AmplitudeBrowser.prototype.reset = function () {
        this.setDeviceId(UUID());
        this.setUserId(undefined);
    };
    AmplitudeBrowser.prototype.getSessionId = function () {
        var _a;
        return (_a = this.config) === null || _a === void 0 ? void 0 : _a.sessionId;
    };
    AmplitudeBrowser.prototype.setSessionId = function (sessionId) {
        var _a;
        var promises = [];
        if (!this.config) {
            this.q.push(this.setSessionId.bind(this, sessionId));
            return returnWrapper(Promise.resolve());
        }
        // Prevents starting a new session with the same session ID
        if (sessionId === this.config.sessionId) {
            return returnWrapper(Promise.resolve());
        }
        this.config.loggerProvider.debug('function setSessionId: ', sessionId);
        var previousSessionId = this.getSessionId();
        var lastEventTime = this.config.lastEventTime;
        var lastEventId = (_a = this.config.lastEventId) !== null && _a !== void 0 ? _a : -1;
        this.config.sessionId = sessionId;
        this.config.lastEventTime = undefined;
        this.config.pageCounter = 0;
        if (isSessionTrackingEnabled(this.config.defaultTracking)) {
            if (previousSessionId && lastEventTime) {
                promises.push(this.track(DEFAULT_SESSION_END_EVENT, undefined, {
                    device_id: this.previousSessionDeviceId,
                    event_id: ++lastEventId,
                    session_id: previousSessionId,
                    time: lastEventTime + 1,
                    user_id: this.previousSessionUserId,
                }).promise);
            }
            this.config.lastEventTime = this.config.sessionId;
        }
        // Fire web attribution event when enable webAttribution tracking
        // 1. has new campaign (call setSessionId from init function)
        // 2. or shouldTrackNewCampaign (call setSessionId from async process(event) when there has new campaign and resetSessionOnNewCampaign = true )
        var isCampaignEventTracked = this.trackCampaignEventIfNeeded(++lastEventId, promises);
        if (isSessionTrackingEnabled(this.config.defaultTracking)) {
            promises.push(this.track(DEFAULT_SESSION_START_EVENT, undefined, {
                event_id: isCampaignEventTracked ? ++lastEventId : lastEventId,
                session_id: this.config.sessionId,
                time: this.config.lastEventTime,
            }).promise);
        }
        this.previousSessionDeviceId = this.config.deviceId;
        this.previousSessionUserId = this.config.userId;
        return returnWrapper(Promise.all(promises));
    };
    AmplitudeBrowser.prototype.extendSession = function () {
        if (!this.config) {
            this.q.push(this.extendSession.bind(this));
            return;
        }
        this.config.lastEventTime = Date.now();
    };
    AmplitudeBrowser.prototype.setTransport = function (transport) {
        if (!this.config) {
            this.q.push(this.setTransport.bind(this, transport));
            return;
        }
        this.config.transportProvider = createTransport(transport);
    };
    AmplitudeBrowser.prototype.identify = function (identify, eventOptions) {
        if (isInstanceProxy(identify)) {
            var queue = identify._q;
            identify._q = [];
            identify = convertProxyObjectToRealObject(new Identify(), queue);
        }
        if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.user_id) {
            this.setUserId(eventOptions.user_id);
        }
        if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.device_id) {
            this.setDeviceId(eventOptions.device_id);
        }
        return _super.prototype.identify.call(this, identify, eventOptions);
    };
    AmplitudeBrowser.prototype.groupIdentify = function (groupType, groupName, identify, eventOptions) {
        if (isInstanceProxy(identify)) {
            var queue = identify._q;
            identify._q = [];
            identify = convertProxyObjectToRealObject(new Identify(), queue);
        }
        return _super.prototype.groupIdentify.call(this, groupType, groupName, identify, eventOptions);
    };
    AmplitudeBrowser.prototype.revenue = function (revenue, eventOptions) {
        if (isInstanceProxy(revenue)) {
            var queue = revenue._q;
            revenue._q = [];
            revenue = convertProxyObjectToRealObject(new Revenue(), queue);
        }
        return _super.prototype.revenue.call(this, revenue, eventOptions);
    };
    AmplitudeBrowser.prototype.trackCampaignEventIfNeeded = function (lastEventId, promises) {
        if (!this.webAttribution || !this.webAttribution.shouldTrackNewCampaign) {
            return false;
        }
        var campaignEvent = this.webAttribution.generateCampaignEvent(lastEventId);
        if (promises) {
            promises.push(this.track(campaignEvent).promise);
        }
        else {
            this.track(campaignEvent);
        }
        this.config.loggerProvider.log('Tracking attribution.');
        return true;
    };
    AmplitudeBrowser.prototype.process = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var currentTime, isEventInNewSession, shouldSetSessionIdOnNewCampaign;
            return __generator(this, function (_a) {
                currentTime = Date.now();
                isEventInNewSession = isNewSession(this.config.sessionTimeout, this.config.lastEventTime);
                shouldSetSessionIdOnNewCampaign = this.webAttribution && this.webAttribution.shouldSetSessionIdOnNewCampaign();
                if (event.event_type !== DEFAULT_SESSION_START_EVENT &&
                    event.event_type !== DEFAULT_SESSION_END_EVENT &&
                    (!event.session_id || event.session_id === this.getSessionId())) {
                    if (isEventInNewSession || shouldSetSessionIdOnNewCampaign) {
                        this.setSessionId(currentTime);
                        if (shouldSetSessionIdOnNewCampaign) {
                            this.config.loggerProvider.log('Created a new session for new campaign.');
                        }
                    }
                    else if (!isEventInNewSession) {
                        // Web attribution should be tracked during the middle of a session
                        // if there has been a chance in the campaign information.
                        this.trackCampaignEventIfNeeded();
                    }
                }
                return [2 /*return*/, _super.prototype.process.call(this, event)];
            });
        });
    };
    AmplitudeBrowser.prototype.logBrowserOptions = function (browserConfig) {
        try {
            var browserConfigCopy = __assign(__assign({}, browserConfig), { apiKey: browserConfig.apiKey.substring(0, 10) + '********' });
            this.config.loggerProvider.debug('Initialized Amplitude with BrowserConfig:', JSON.stringify(browserConfigCopy));
        }
        catch (e) {
            /* istanbul ignore next */
            this.config.loggerProvider.error('Error logging browser config', e);
        }
    };
    return AmplitudeBrowser;
}(AmplitudeCore));
export { AmplitudeBrowser };
