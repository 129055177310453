import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Observable } from 'rxjs';

import { LoggedInUserState, LoggedInUser } from '@modules/auth/store';
import { Select } from '@ngxs/store';

@Component({
  selector: 'pg-side-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pg-side-nav.component.html',
  styleUrls: ['pg-side-nav.component.scss'],
})
export class PgSideNavComponent {
  @Input() sidenavStyle!: string;
  @Input() sideNavItems!: SideNavItems;
  @Input() sideNavSections!: SideNavSection[];

  @Select(LoggedInUserState.getLoggedInUser) user$: Observable<LoggedInUser>;

  constructor(public navigationService: NavigationService) {}
}
