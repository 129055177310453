import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pg-sciops-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sciops-modal.component.html',
  styleUrls: ['sciops-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SciopsModalComponent implements OnInit {
  userAgentString: string;
  userBrowser: string;

  @Input() urlLink: string;

  constructor(private modalService: NgbModal) {}
  ngOnInit() {
    this.userAgentString = window.navigator.userAgent;
    this.userBrowser = this.getUserBrowser(this.userAgentString);
  }
  close() {
    this.modalService.dismissAll();
  }

  getUserBrowser(userAgentString: string) {
    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false;

    if (chromeAgent) return 'Chrome';
    else if (firefoxAgent) return 'FireFox';
    else if (safariAgent) return 'Safari';
    else if (operaAgent) return 'Opera';
    else return 'Unknown';
  }
}
