import { ChangeDetectionStrategy, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoggedInUser, LoggedInUserState } from '@modules/auth/store';
import { UpdateNotificationDeleted, UpdateNotificationRead } from '@modules/notifications/store/notifications.actions';
import { NotificationsState, NotificationsStateModel } from '@modules/notifications/store/notifications.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PortalNotification } from '../../../../generated/graphql';

@Component({
  selector: 'pg-notifications-alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notifications-alert.component.html',
  styleUrls: ['notifications-alert.component.scss'],
})
export class NotificationsAlertComponent implements OnInit {
  @ViewChild('dropDown') dropDown!: ElementRef;
  @Select(NotificationsState) notifications$: Observable<NotificationsStateModel>;
  @Select(LoggedInUserState.getLoggedInUser) user$: Observable<LoggedInUser>;
  trash: IconProp = ['fal', 'trash-can'];

  notificationForModal: PortalNotification | undefined;

  constructor(private store: Store, private modalService: NgbModal, private router: Router, private ngZone: NgZone) {}

  ngOnInit() {}

  openNotification(content: TemplateRef<any>, notification: PortalNotification) {
    this.notificationForModal = notification;
    this.store.dispatch(new UpdateNotificationRead({ notificationId: notification._id, updateNotificationDto: { read: true, updateTime: new Date() }}));
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  archiveNotification() {
    this.modalService.dismissAll();
    if (this.notificationForModal) {
      this.store.dispatch(new UpdateNotificationDeleted({ notificationId: this.notificationForModal._id }));
      this.notificationForModal = undefined;
    }
  }

  goToComic(comicURLParam: string) {
    this.modalService.dismissAll();
    this.ngZone.run(() => {
      this.router.navigate(['/student/comics/' + comicURLParam]);
    });
  }
}
