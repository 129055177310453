/* tslint:disable: ordered-imports*/

import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';

import { featherIcons } from './icons.feather';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fontAwesomeSolidIcons } from './icons.font-awesome-solid';
import { fontAwesomeRegularIcons } from './icons.font-awesome-regular';
import { fontAwesomeBrandsIcons } from './icons.font-awesome-brands';
import { fontAwesomeDuotoneIcons } from './icons.font-awesome-duotone';
import { fontAwesomeLightIcons } from './icons.font-awesome-light';
//@ts-ignore
import * as fontAwesomeKitIcons from '@awesome.me/kit-55ca5fc4e1/icons/kit/custom';

@NgModule({
  imports: [FeatherModule.pick(featherIcons)],
  exports: [FeatherModule, FontAwesomeModule],
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fontAwesomeSolidIcons, fontAwesomeRegularIcons, fontAwesomeBrandsIcons, fontAwesomeDuotoneIcons, fontAwesomeLightIcons, fontAwesomeKitIcons);
  }
}
