import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoggedInUser } from '@modules/auth/store';
import { Store } from '@ngxs/store';
import { DeleteMyAccountGQL, GetUserDto } from 'generated/graphql';
import { SubSink } from 'subsink';
import { of } from 'rxjs';
import { LogoutUser } from '@modules/auth/store/loggedInUser.actions';

/**
 * @deprecated - replaced with pg-delete-confirm-modal, deletion logic moved to teacher-accounts-settings.component
 */
@Component({
  selector: 'pg-delete-account-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit {
  subs = new SubSink();

  @Input() modal: any;
  constructor(private deleteMyAccountGQL: DeleteMyAccountGQL, private store: Store) {}
  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  deleteAccount() {
    this.modal.dismiss();
    const user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser.user);
    if (user) {
      const userId: GetUserDto = { _id: user._id };
      this.subs.sink = this.deleteMyAccountGQL.mutate({ userId }).subscribe(
        ({ data }) => {
          if (data?.removeUser._id) {
            this.store.dispatch(new LogoutUser());
          }
        },
        (err) => {
          console.error(JSON.stringify(err));
          return of(JSON.stringify(err));
        }
      );
    }
    this.store.dispatch(new LogoutUser());
  }
}
