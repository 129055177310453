import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DashboardHeadCompactComponent } from './dashboard-head-compact/dashboard-head-compact.component';
import { DashboardHeadComponent } from './dashboard-head/dashboard-head.component';
import { PgDashboardHeadComponent } from './pg-dashboard-head/pg-dashboard-head.component';
import { SideNavItemProComponent } from './side-nav-item-pro/side-nav-item-pro.component';
import { TopNavSearchComponent } from './top-nav-search/top-nav-search.component';
import { TopNavUserComponent } from './top-nav-user/top-nav-user.component';
import { MainTopNavComponent } from './main-top-nav/main-top-nav.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { NotificationsAlertComponent } from './notifications-alert/notifications-alert.component';

export const components = [
  SideNavItemProComponent,
  BreadcrumbsComponent,
  DashboardHeadComponent,
  TopNavUserComponent,
  TopNavSearchComponent,
  DashboardHeadCompactComponent,
  PgDashboardHeadComponent,
  MainTopNavComponent,
  MainFooterComponent,
  NotificationsAlertComponent,
];

export * from './side-nav-item-pro/side-nav-item-pro.component';
export * from './breadcrumbs/breadcrumbs.component';
export * from './dashboard-head/dashboard-head.component';
export * from './top-nav-user/top-nav-user.component';
export * from './top-nav-search/top-nav-search.component';
export * from './dashboard-head-compact/dashboard-head-compact.component';
export * from './pg-dashboard-head/pg-dashboard-head.component';
export * from './main-top-nav/main-top-nav.component';
export * from './main-footer/main-footer.component';
export * from './notifications-alert/notifications-alert.component';
