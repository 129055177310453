<div class="logo">
  <svg width="70%" viewBox="0 0 1024 628.45">
    <g id="PlasmaProton">
      <path
        id="bottom"
        class="pg-logo-blue"
        d="M794.79,226.37c-27-8.59-64.46-18.6-111.64-20.55C676.52,261.49,631,310.14,562.27,327.56c-32.44,8.22-113,22.83-145.55,26.09-18.5,1.85,52.13,142.41,52.13,142.41,1.57,6.59,6.52,20.65-9.77,26.09l-66.34,18.92c-17.22,4.1-28.16-5.88-55.32-47.19-14.53-22.1-38.07-80.62-54.66-146.41-30.46,10-57.43,20.75-80.52,32.09-5.69,2.8-10.94,5.54-15.82,8.21,26.82,110.84,132.11,240.68,303.05,240.68,207.18,0,317.89-173.22,317.89-313.77C807.36,283.24,803.36,255,794.79,226.37Z" />
      <path
        id="right"
        class="pg-logo-orange"
        d="M657.21,172.39s314.65-39.1,361.64,69.87c40.71,94.43-172.15,182.29-205.61,188.92a125,125,0,0,0,8.4-33.85s98.17-73.84,78.69-130.59C881.43,211.66,785,178.83,657.21,172.39Z" />
      <path
        id="left"
        class="pg-logo-orange"
        d="M592.55,191.23c-2.32-32.9-34.77-57.79-72.48-55.59-28.05,1.64-211.4,38.51-347.83,104.7C18.22,315.05-31.25,413.6,18.8,481.69c42.67,58.07,214.08,57.52,214.08,57.52S220,526.42,205,504.42c0,0-121.93-21.88-108.84-73.34C106.5,390.33,190,303,532.8,254.75,562.55,250.56,594.78,222.86,592.55,191.23Z" />
      <path
        id="top"
        class="pg-logo-blue"
        d="M302.66,115.57C348.29,93.83,428,65.16,511.21,58c78.34-6.78,136,16.61,161.51,80.13,17.37-1.17,45.47-2.61,78.65-2.61h.09A315,315,0,0,0,206.19,184c20.71-8.51,41.57-16.29,61.9-23.35C270.93,142.48,276.64,128,302.66,115.57Z" />
    </g>
  </svg>
</div>
<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <nav class="navbar navbar-with-back-button shadow-0" *ngIf="showNav">
      <div class="container-fluid">
        <a routerLink="/" class="nav-link" data-cy="backBtn">
          <div class="navbar-back">Back</div>
        </a>
      </div>
    </nav>
    <main>
      <sbpro-alert data-cy="apiErrorAlert" *ngIf="isAPIError" [icon]="true" classes="alert-warning mt-5 mx-10" heading="Warning! Failed to connect to server">
        <div class="alert-icon-aside">
          <fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon>
        </div>
        Our API server is being blocked. Please whitelist the domain <b>https://api.plasma.games</b> to have access to Plasma Games. If you need more assistance please have your
        district technical department contact us at <b>support&#64;plasma.games</b> to help resolve this issue.
      </sbpro-alert>
      <ng-content></ng-content>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <footer class="footer-admin mt-auto" [ngClass]="{ 'footer-light': true, 'footer-dark': false }">
      <div class="container-fluid px-4">
        <div class="gx-4">
          <div class="text-md-center small footer-text"><a routerLink="/legal">EUALA &middot; Privacy Policy</a></div>
        </div>
      </div>
    </footer>
  </div>
</div>
