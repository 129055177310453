import { SideNavItems, SideNavSection } from '../models';

export const sideNavSections: SideNavSection[] = [
  {
    text: 'DASHBOARD',
    items: ['dashboard', 'resources', 'lessons', 'classes'],
  },
];

export const sideNavItems: SideNavItems = {
  dashboard: {
    faIcon: 'user-graduate',
    text: 'District Portal',
    link: '/district',
  },
  resources: {
    text: 'Teacher Support',
    faIcon: 'books',
    submenu: [
      {
        link: '/district/resources/start',
        text: 'Getting Started',
      },
      /*{
        link: '/district/resources/coach',
        text: 'Coaching Sessions',
      },*/
      {
        link: '/district/resources/reading',
        text: 'Reading Strategies',
      },
      {
        link: '/district/resources/tutorials',
        text: 'Tutorials',
      },
      {
        link: '/district/resources/support',
        text: 'Extra Support',
      },
    ],
  },
  lessons: {
    text: 'Lessons',
    faIcon: 'pencil-alt',
    submenu: [
      {
        link: '/district/lessons/activities',
        queryParams: { course: 'ms' },
        text: 'Middle School Activities',
      },
      {
        link: '/district/lessons/activities',
        queryParams: { course: 'chem' },
        text: 'HS Chemistry Activities',
      },
      {
        link: '/district/lessons/activities',
        queryParams: { course: 'ps' },
        text: 'HS Physical Science Activities',
      },
    ],
  },
  // units: {
  //   text: 'Units',
  //   faIcon: 'chalkboard-teacher',
  //   submenu: [
  //     {
  //       link: '/teacher/units/activities',
  //       text: 'Student Activities',
  //     },
  //     {
  //       link: '/teacher/units/standards',
  //       text: 'Unit Standards',
  //     },
  //     {
  //       link: '/teacher/units/pacing',
  //       text: 'Pacing Guides',
  //     },
  //   ],
  // },
  classes: {
    text: 'My Teachers',
    faIcon: 'users',
    link: '/district/teachers',
  },
  // account: {
  //   text: 'Account',
  //   faIcon: 'user-cog',
  //   link: '/district/account',
  // },
};
