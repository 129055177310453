<div class="modal-header"><h1 class="modal-title">Play Sci-Ops: Global Defense</h1></div>
<div class="modal-body">
  <sbpro-alert [icon]="true" classes="alert-pg-light-red sb-alert-solid"
    ><div class="alert-wrapper text-center">
      <fa-icon class="browser-icon" [icon]='["fas", "exclamation-triangle"]'></fa-icon>We recommend playing Sci-Ops: Global Defense on a Google Chrome Browser<fa-icon
        class="browser-icon"
        [icon]='["fas", "exclamation-triangle"]'
      ></fa-icon></div></sbpro-alert
  ><sbpro-card-image
    class="pointer"
    src="/assets/img/backgrounds/studentportal_game.jpg"
    alt="Sci-Ops: Global Defense"
    placement="top"
    [classes]='["lift", "lift-sm"]'
    (click)="close()"
    [routerLink]="urlLink"
    ><div class="card-body" data-cy="btnModulePlaySciOps">
      <p class="card-text">Click to Play</p>
      <p class="game-name">Sci-Ops: Global Defense</p>
    </div></sbpro-card-image
  >
</div>
