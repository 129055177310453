import { __read, __spreadArray } from "tslib";
import { filter, map, merge, switchMap, take, timeout, EMPTY } from 'rxjs';
import { filterOutNonTrackableEvents, getClosestElement } from '../helpers';
import { AMPLITUDE_ELEMENT_CLICKED_EVENT } from '../constants';
export function trackActionClick(_a) {
    var amplitude = _a.amplitude, allObservables = _a.allObservables, options = _a.options, getEventProperties = _a.getEventProperties, shouldTrackEvent = _a.shouldTrackEvent, shouldTrackActionClick = _a.shouldTrackActionClick;
    var clickObservable = allObservables.clickObservable, mutationObservable = allObservables.mutationObservable, navigateObservable = allObservables.navigateObservable;
    var filteredClickObservable = clickObservable.pipe(filter(function (click) {
        // Filter out regularly tracked click events that are already handled in trackClicks
        return !shouldTrackEvent('click', click.closestTrackedAncestor);
    }), map(function (click) {
        // overwrite the closestTrackedAncestor with the closest element that is on the actionClickAllowlist
        var closestActionClickEl = getClosestElement(click.event.target, options.actionClickAllowlist);
        click.closestTrackedAncestor = closestActionClickEl;
        // overwrite the targetElementProperties with the properties of the closestActionClickEl
        if (click.closestTrackedAncestor !== null) {
            click.targetElementProperties = getEventProperties(click.type, click.closestTrackedAncestor);
        }
        return click;
    }), filter(filterOutNonTrackableEvents), filter(function (clickEvent) {
        // Only track change on elements that should be tracked
        return shouldTrackActionClick('click', clickEvent.closestTrackedAncestor);
    }));
    var changeObservables = [mutationObservable];
    /* istanbul ignore next */
    if (navigateObservable) {
        changeObservables.push(navigateObservable);
    }
    var mutationOrNavigate = merge.apply(void 0, __spreadArray([], __read(changeObservables), false));
    var actionClicks = filteredClickObservable.pipe(
    // If a mutation occurs within 0.5 seconds of a click event (timeout({ first: 500 })), it emits the original first click event.
    // take 1 to only limit the action events in case there are multiple
    switchMap(function (click) {
        return mutationOrNavigate.pipe(take(1), timeout({ first: 500, with: function () { return EMPTY; } }), // in case of timeout, map to empty to prevent any click from being emitted
        map(function () { return click; }));
    }));
    return actionClicks.subscribe(function (actionClick) {
        /* istanbul ignore next */
        amplitude === null || amplitude === void 0 ? void 0 : amplitude.track(AMPLITUDE_ELEMENT_CLICKED_EVENT, getEventProperties('click', actionClick.closestTrackedAncestor), {
            time: actionClick.timestamp,
        });
    });
}
