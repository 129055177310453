import { Injectable } from '@angular/core';
import { defer, finalize, Observable, of, startWith } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { catchError } from 'rxjs/operators';
import { GraphQLError } from 'graphql/error';
import { ApolloError } from '@apollo/client/errors';

@Injectable()
export class NetworkLoadingService {
  /**
   * Set the loading state of ApolloGraphQL result to true before call is made
   * Once call is completed, set loading to false
   * @param networkCall - The network call to make
   * Example usage:
   *  `return this.loadingService.withLoading(() => this.getStudentActivitySearchGQL.fetch({ searchDto }, { fetchPolicy: 'network-only', errorPolicy: 'all' }));`
   */
  withLoading<T>(networkCall: () => Observable<ApolloQueryResult<T>>): Observable<ApolloQueryResult<T>> {
    return defer(() => networkCall()).pipe(
      startWith({ loading: true, data: undefined as T, errors: [], error: undefined, networkStatus: 1, partial: false }), // Initial loading state
      catchError((error) => {
        // Handle error, potentially logging or transforming the error
        return of({
          loading: false,
          data: undefined as T,
          errors: [new GraphQLError(error.message)],
          error: new ApolloError({ errorMessage: error.message }),
          networkStatus: 8,
          partial: false,
        });
      }),
      finalize(() => ({ loading: false }))
    );
  }
}
