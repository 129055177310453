import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Component, Optional, Inject, Input, HostBinding, Output, HostListener, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

const MDB_OPTION_PARENT = new InjectionToken('MDB_OPTION_PARENT');
const MDB_OPTION_GROUP = new InjectionToken('MDB_OPTION_GROUP');
class MdbOptionComponent {
    _el;
    _cdRef;
    _parent;
    group;
    value;
    hidden = false;
    get label() {
        return this._label || this._el.nativeElement.textContent;
    }
    set label(newValue) {
        this._label = newValue;
    }
    _label;
    get isHidden() {
        return this.hidden;
    }
    get disabled() {
        return this._disabled || (this.group && this.group.disabled);
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    _disabled = false;
    selectionChange = new EventEmitter();
    _optionHeight;
    _selected = false;
    _active = false;
    _previousLabelValue = '';
    _multiple = false;
    clicked = false;
    _labelChange = new Subject();
    clickSource = new Subject();
    click$ = this.clickSource.asObservable();
    constructor(_el, _cdRef, _parent, group) {
        this._el = _el;
        this._cdRef = _cdRef;
        this._parent = _parent;
        this.group = group;
        this.clicked = false;
    }
    option = true;
    get active() {
        return this._active;
    }
    get selected() {
        return this._selected;
    }
    get optionHeight() {
        return this._optionHeight;
    }
    get role() {
        return 'option';
    }
    get isDisabled() {
        return this.disabled ? true : false;
    }
    get isSelected() {
        return this.selected;
    }
    onClick() {
        this.clickSource.next(this);
    }
    getLabel() {
        return this._el.nativeElement.textContent;
    }
    get offsetHeight() {
        return this._el.nativeElement.offsetHeight;
    }
    ngOnInit() {
        if (this._parent && this._parent.optionHeight) {
            this._optionHeight = this._parent.optionHeight;
        }
        if (this._parent && this._parent.multiple) {
            this._multiple = true;
        }
    }
    ngAfterViewChecked() {
        // We need to let parent component know about dynamic label changes, so it can trigger
        // change detection and update value displayed in input. We only need to do that for
        // selected options, because other options will be hidden inside the dropdown, and their
        // labels will be updated automatically when dropdown is opened.
        if (this._selected) {
            const label = this.getLabel();
            if (label !== this._previousLabelValue) {
                this._previousLabelValue = label;
                this._labelChange.next();
            }
        }
    }
    ngOnDestroy() {
        this._labelChange.complete();
    }
    select() {
        if (!this._selected) {
            this._selected = this._multiple ? !this._selected : true;
            this.selectionChange.emit(this);
            this._cdRef.markForCheck();
        }
    }
    deselect() {
        if (this._selected) {
            this._selected = false;
            this.selectionChange.emit(this);
            this._cdRef.markForCheck();
        }
    }
    setActiveStyles() {
        if (!this._active) {
            this._active = true;
            this._cdRef.markForCheck();
        }
    }
    setInactiveStyles() {
        if (this._active) {
            this._active = false;
            this._cdRef.markForCheck();
        }
    }
    static ngAcceptInputType_disabled;
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionComponent, deps: [{ token: i0.ElementRef }, { token: i0.ChangeDetectorRef }, { token: MDB_OPTION_PARENT, optional: true }, { token: MDB_OPTION_GROUP, optional: true }], target: i0.ɵɵFactoryTarget.Component });
    static ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "17.0.9", type: MdbOptionComponent, selector: "mdb-option", inputs: { value: "value", label: "label", disabled: "disabled" }, outputs: { selectionChange: "selectionChange" }, host: { listeners: { "click": "onClick()" }, properties: { "class.hidden": "this.isHidden", "class.disabled": "this.disabled", "class.option": "this.option", "class.active": "this.active", "class.selected": "this.selected", "style.height.px": "this.optionHeight", "attr.role": "this.role", "attr.aria-disabled": "this.isDisabled", "attr.aria-selected": "this.isSelected" } }, ngImport: i0, template: "<span class=\"option-text\" ngClass=\"{'active', active}\">\n  <input *ngIf=\"_multiple\" class=\"form-check-input\" type=\"checkbox\" [checked]=\"selected\" [disabled]=\"disabled\" />\n  <ng-content></ng-content>\n</span>\n<ng-content select=\".option-icon-container\"></ng-content>\n", dependencies: [{ kind: "directive", type: i1.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }, { kind: "directive", type: i1.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionComponent, decorators: [{
            type: Component,
            args: [{ selector: 'mdb-option', template: "<span class=\"option-text\" ngClass=\"{'active', active}\">\n  <input *ngIf=\"_multiple\" class=\"form-check-input\" type=\"checkbox\" [checked]=\"selected\" [disabled]=\"disabled\" />\n  <ng-content></ng-content>\n</span>\n<ng-content select=\".option-icon-container\"></ng-content>\n" }]
        }], ctorParameters: () => [{ type: i0.ElementRef }, { type: i0.ChangeDetectorRef }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [MDB_OPTION_PARENT]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [MDB_OPTION_GROUP]
                }] }], propDecorators: { value: [{
                type: Input
            }], label: [{
                type: Input
            }], isHidden: [{
                type: HostBinding,
                args: ['class.hidden']
            }], disabled: [{
                type: HostBinding,
                args: ['class.disabled']
            }, {
                type: Input
            }], selectionChange: [{
                type: Output
            }], option: [{
                type: HostBinding,
                args: ['class.option']
            }], active: [{
                type: HostBinding,
                args: ['class.active']
            }], selected: [{
                type: HostBinding,
                args: ['class.selected']
            }], optionHeight: [{
                type: HostBinding,
                args: ['style.height.px']
            }], role: [{
                type: HostBinding,
                args: ['attr.role']
            }], isDisabled: [{
                type: HostBinding,
                args: ['attr.aria-disabled']
            }], isSelected: [{
                type: HostBinding,
                args: ['attr.aria-selected']
            }], onClick: [{
                type: HostListener,
                args: ['click']
            }] } });

class MdbOptionGroupComponent {
    _parent;
    optionGroup = true;
    _optionHeight = 48;
    label;
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = value;
    }
    _disabled = false;
    constructor(_parent) {
        this._parent = _parent;
    }
    ngOnInit() {
        if (this._parent && this._parent.visibleOptions && this._parent.optionHeight) {
            this._optionHeight = this._parent.optionHeight;
        }
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionGroupComponent, deps: [{ token: MDB_OPTION_PARENT, optional: true }], target: i0.ɵɵFactoryTarget.Component });
    static ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "17.0.9", type: MdbOptionGroupComponent, selector: "mdb-option-group", inputs: { label: "label", disabled: "disabled" }, host: { properties: { "class.option-group": "this.optionGroup" } }, providers: [{ provide: MDB_OPTION_GROUP, useExisting: MdbOptionGroupComponent }], ngImport: i0, template: "<label\n  class=\"option-group-label\"\n  [style.height.px]=\"_optionHeight\"\n  [style.line-height.px]=\"_optionHeight\"\n  >{{ label }}</label\n>\n<ng-content select=\"mdb-option\"></ng-content>\n", changeDetection: i0.ChangeDetectionStrategy.OnPush });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionGroupComponent, decorators: [{
            type: Component,
            args: [{ selector: 'mdb-option-group', changeDetection: ChangeDetectionStrategy.OnPush, providers: [{ provide: MDB_OPTION_GROUP, useExisting: MdbOptionGroupComponent }], template: "<label\n  class=\"option-group-label\"\n  [style.height.px]=\"_optionHeight\"\n  [style.line-height.px]=\"_optionHeight\"\n  >{{ label }}</label\n>\n<ng-content select=\"mdb-option\"></ng-content>\n" }]
        }], ctorParameters: () => [{ type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [MDB_OPTION_PARENT]
                }] }], propDecorators: { optionGroup: [{
                type: HostBinding,
                args: ['class.option-group']
            }], label: [{
                type: Input
            }], disabled: [{
                type: Input
            }] } });

class MdbOptionModule {
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionModule, declarations: [MdbOptionComponent, MdbOptionGroupComponent], imports: [CommonModule], exports: [MdbOptionComponent, MdbOptionGroupComponent] });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionModule, imports: [CommonModule] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.9", ngImport: i0, type: MdbOptionModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [MdbOptionComponent, MdbOptionGroupComponent],
                    imports: [CommonModule],
                    exports: [MdbOptionComponent, MdbOptionGroupComponent],
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { MDB_OPTION_GROUP, MDB_OPTION_PARENT, MdbOptionComponent, MdbOptionGroupComponent, MdbOptionModule };

