import { Injectable } from '@angular/core';
import { SendRequestAccessDto, SendRequestAccessFormGQL, SendRequestAccessFormMutation } from '../../../generated/graphql';
import { Observable } from 'rxjs';
import { MutationResult } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class RequestAccessService {
  constructor(private sendRequestAccessFormGQL: SendRequestAccessFormGQL) {}

  sendRequestAccessForm(emailBody: SendRequestAccessDto): Observable<MutationResult<SendRequestAccessFormMutation>> {
    return this.sendRequestAccessFormGQL.mutate({ emailBody }, { fetchPolicy: 'no-cache' });
  }
}
