<div class="toast show mb-1" [ngClass]="classes" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
  <div class="toast-header justify-content-between" [ngClass]="headerClasses">
    <ng-container *ngIf="headerIsTemplate">
      <ng-template *ngTemplateOutlet="header; context: toastContext"></ng-template>
    </ng-container>
    <ng-container *ngIf="!headerIsTemplate && header">{{ header }}</ng-container>
    <button type="button" (click)="toastService.remove(toastID)" aria-label="Close" class="ms-2 mb-1 btn-close float-end"><span aria-hidden="true">×</span></button>
  </div>
  <div class="toast-body" [ngClass]="bodyClasses">
    <ng-container *ngIf="bodyIsTemplate">
      <ng-template *ngTemplateOutlet="body; context: toastContext"></ng-template>
    </ng-container>
    <ng-container *ngIf="!bodyIsTemplate && body">
      {{ body }}
    </ng-container>
  </div>
</div>
