<li mdbDropdown class="nav-item dropdown no-caret me-3 me-lg-0 dropdown dropdown-user" *ngIf="user$ | async" ngbDropdown [placement]="placement" display="dynamic">
  <a
    class="nav-link dropdown-toggle d-flex align-items-center"
    id="navbarDropdownUserImage"
    mdbDropdownToggle
    data-cy="userMenu"
    role="button"
    aria-haspopup="true"
    aria-expanded="false">
    <img class="rounded-circle" height="44" [src]="avatar$ | async" />
  </a>
  <ul class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" mdbDropdownMenu aria-labelledby="navbarDropdownUserImage">
    <h6 class="dropdown-header d-flex align-items-center">
      <img class="rounded-circle me-3" height="44" [src]="avatar$ | async" />
      <div class="dropdown-user-details">
        <div class="dropdown-user-details-name" *ngIf="(user$ | async)?.profile?.firstName">{{ (user$ | async)?.profile.firstName }} {{ (user$ | async)?.profile.lastName }}</div>
        <div class="dropdown-user-details-email">{{ (user$ | async)?.email }}</div>
      </div>
    </h6>
    <div class="dropdown-divider"></div>
    <li>
      <a class="dropdown-item" *ngIf="(user$ | async)?.role == 'TEACHER'" routerLink="/teacher/profile">
        <div class="dropdown-item-icon">
          <i-feather name="settings"></i-feather>
        </div>
        Account</a
      >
    </li>
    <li>
      <a class="dropdown-item" *ngIf="(user$ | async)?.role == 'DISTRICT'" routerLink="/district/profile">
        <div class="dropdown-item-icon">
          <i-feather name="settings"></i-feather>
        </div>
        Account</a
      >
    </li>
    <li>
      <a class="dropdown-item" *ngIf="(user$ | async)?.role == 'ADMIN'" routerLink="/admin/profile">
        <div class="dropdown-item-icon">
          <i-feather name="settings"></i-feather>
        </div>
        Account</a
      >
    </li>
    <li>
      <a class="dropdown-item" (click)="logout()">
        <div class="dropdown-item-icon">
          <i-feather name="log-out"></i-feather>
        </div>
        Logout</a
      >
    </li>
  </ul>
</li>
