import { Component, OnInit } from '@angular/core';

/**
 * Heavily leveraged code from:
 * https://dev.to/valorsoftware/google-translate-customization-under-nextjs-h69
 */
@Component({
  selector: 'pg-language-loader',
  templateUrl: './language-loader.component.html',
})
export class LanguageLoaderComponent implements OnInit {
  ngOnInit() {
    this.addGoogleTranslateToHead();
  }

  addGoogleTranslateToHead(): void {
    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement({ pageLanguage: 'en' });
    };

    const googleTranslateElement = document.createElement('script');
    googleTranslateElement.type = 'text/javascript';
    googleTranslateElement.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(googleTranslateElement);
  }
}
