import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CardBase } from '@common/classes';

@Component({
  selector: 'pg-card-icon-link',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './card-icon-link.component.html',
  styleUrls: ['card-icon-link.component.scss'],
})
export class CardIconLinkComponent extends CardBase implements OnInit {
  iconBackground = 'bg-primary';
  textBackground = 'text-primary';
  @Input() linkUrl = '/';
  @Input() color = 'primary';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.iconBackground = `bg-${this.color}`;
    this.textBackground = `text-${this.color}`;
  }
}
