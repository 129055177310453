<nav class="navbar navbar-expand-lg navbar-light bg-white shadow" id="sidenavAccordion">
  <a class="navbar-brand d-sm-block ms-3" routerLink="/"><img src="assets/img/logo/plasma_logo_h.png" alt="Plasma Games Logo" /></a>
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" data-bs-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse me-3" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav main-nav align-items-center ms-auto">
      <li class="nav-item fw-bold text-link fw-700">
        <a class="nav-link link-primary" routerLink="/about"> Our Mission </a>
      </li>
      <li class="nav-item fw-bold text-link fw-700">
        <a class="nav-link link-primary" routerLink="/educators"> Educators </a>
      </li>
      <li class="nav-item">
        <a class="btn btn-primary text-link fw-900" routerLink="/contact"> Schedule a Webchat </a>
      </li>
      <!-- If the user is not logged in show login button -->
      <div *ngIf="user === undefined" class="nav-item">
        <a class="btn btn-primary text-link fw-900" routerLink="/auth/login">
          <fa-icon class="me-2" [icon]="['far', 'atom']"></fa-icon> Log In
        </a>
      </div>
      <!--  TODO Inset user profile picture HERE -->
      <div *ngIf="user !== undefined" class="nav-item">
        <a class="btn btn-primary text-link fw-900" routerLink="/auth/login">
          <fa-icon class="me-2" [icon]="['fad', 'flask']"></fa-icon> Portal
        </a>
      </div>
    </ul>
  </div>
</nav>