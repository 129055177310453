import { computed, Directive, effect, inject, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UtilityService } from '@common/services';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { defer } from 'rxjs';

@Directive()
export class MdbDashboardLayoutLogicClass {
  protected readonly utility = inject(UtilityService);

  @ViewChild('sidenav', { static: true }) sidenav!: MdbSidenavComponent;

  readonly LARGE = 'Large';
  private isFirstInitialization: boolean = true;
  protected readonly mediaQuery = toSignal<'XLarge' | 'Large' | 'unknown' | string>(defer(() => this.utility.getMediaQuery()));
  protected mode = computed<'side' | 'over'>(() => {
    return this.mediaQuery()!.includes(this.LARGE) ? 'side' : 'over';
  });

  protected hidden = computed<boolean>(() => {
    return this.mediaQuery()!.includes(this.LARGE) ? false : true;
  });

  protected focus = computed<boolean>(() => {
    const firstTimeLoadMode = this.mode();
    if (this.isFirstInitialization) {
      return firstTimeLoadMode == 'over' ? false : true;
    }
    return true;
  });

  constructor() {
    effect(() => {
      const query = this.mediaQuery();
      if (query?.includes(this.LARGE)) {
        setTimeout(() => {
          this.sidenav.show();
        }, 0);
      } else {
        setTimeout(() => {
          this.sidenav.hide();
        }, 0);
      }
      this.isFirstInitialization = false;
    });
  }
}
