import * as i0 from '@angular/core';
import { SecurityContext, ElementRef, ChangeDetectorRef, Component, Inject, Input, NgModule, Optional } from '@angular/core';
import * as i2 from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

class Icons {
    constructor(icons) {
        this.icons = icons;
    }
}

function uppercamelcase(str) {
    return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (firstLetter) => {
        return firstLetter.toUpperCase();
    }).replace(/[-_]/g, '');
}

class FeatherComponent {
    constructor(elem, changeDetector, icons, sanitizer) {
        this.elem = elem;
        this.changeDetector = changeDetector;
        this.icons = icons;
        this.sanitizer = sanitizer;
    }
    ngOnChanges(changes) {
        // icons are provided as an array of objects because of "multi: true"
        const icons = Object.assign({}, ...this.icons);
        const svg = icons[uppercamelcase(changes.name.currentValue)] || '';
        if (!svg) {
            console.warn(`Icon not found: ${changes.name.currentValue}\n` +
                `Refer to documentation on https://github.com/michaelbazos/angular-feather`);
        }
        // Since the icons are precompiled we can trust them as safe html.
        this.elem.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(svg));
        this.changeDetector.markForCheck();
    }
}
FeatherComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherComponent, deps: [{ token: ElementRef }, { token: ChangeDetectorRef }, { token: Icons }, { token: DomSanitizer }], target: i0.ɵɵFactoryTarget.Component });
FeatherComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "12.2.17", type: FeatherComponent, selector: "i-feather, feather-icon", inputs: { name: "name" }, usesOnChanges: true, ngImport: i0, template: "<ng-content></ng-content>\n", styles: [":host{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}\n"] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'i-feather, feather-icon',
                    templateUrl: './feather.component.html',
                    styleUrls: ['./feather.component.scss'],
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef, decorators: [{
                    type: Inject,
                    args: [ElementRef]
                }] }, { type: i0.ChangeDetectorRef, decorators: [{
                    type: Inject,
                    args: [ChangeDetectorRef]
                }] }, { type: Icons, decorators: [{
                    type: Inject,
                    args: [Icons]
                }] }, { type: i2.DomSanitizer, decorators: [{
                    type: Inject,
                    args: [DomSanitizer]
                }] }]; }, propDecorators: { name: [{
                type: Input
            }] } });

class FeatherModule {
    constructor(icons) {
        this.icons = icons;
        if (!this.icons) {
            throw new Error(`No icon provided. Make sure to use 'FeatherModule.pick({ ... })' when importing the module\n` +
                `Refer to documentation on https://github.com/michaelbazos/angular-feather`);
        }
    }
    static pick(icons) {
        return {
            ngModule: FeatherModule,
            providers: [
                { provide: Icons, multi: true, useValue: icons }
            ]
        };
    }
}
FeatherModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherModule, deps: [{ token: Icons, optional: true }], target: i0.ɵɵFactoryTarget.NgModule });
FeatherModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherModule, declarations: [FeatherComponent], exports: [FeatherComponent] });
FeatherModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherModule });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: FeatherModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [
                        FeatherComponent
                    ],
                    exports: [
                        FeatherComponent
                    ]
                }]
        }], ctorParameters: function () { return [{ type: Icons, decorators: [{
                    type: Optional
                }] }]; } });

/*
 * Public API Surface of angular-feather
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FeatherComponent, FeatherModule };

