<div
  class="page-header page-header-dark {{ backgroundColor }}"
  [ngClass]="{
    'pb-10': !simplified
  }"
>
  <div class="container-fluid px-10">
    <div class="page-header-content pt-2 pb-4">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto mt-4">
          <div class="page-header-title-wrapper" [tourAnchor]="tourAnchorTag">
            <h1 class="page-header-title">
              <div class="page-header-icon">
                <div *ngIf="icon; then featherIcon; else faIconBlock"></div>
                <ng-template #featherIcon><i-feather *ngIf="icon" [name]="icon"></i-feather></ng-template
                ><ng-template #faIconBlock><fa-icon [icon]="[faIconStyle, faIcon]"></fa-icon></ng-template>
              </div>
              <span>{{ title }}</span>
            </h1>
            <div class="page-header-subtitle">{{ description }}</div>
            <div *ngIf="headerBadge"><ng-content select=".header-badge"></ng-content></div>
            <sb-breadcrumbs *ngIf="breadcrumbs"></sb-breadcrumbs>
          </div>
        </div>
        <div class="col-12 col-xl-auto mt-4">
          <ng-content select=".header-btn"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
