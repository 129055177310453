<div class="modal-header text-center bg-pg-light-red">
  <h1 class="modal-title w-100 text-white">
    <fa-icon class="browser-icon" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    WARNING
    <fa-icon class="browser-icon" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
  </h1>
  <button class="ms-2 mb-1 btn-close" type="button" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body">
  This will permanently delete your account. If you have any classrooms without a co-teacher they will also be deleted. All students in these classrooms will loose access to Plasma
  Games resources.
  <h6>Are you absolutely sure you want to proceed?</h6>
</div>
<div class="modal-footer">
  <button (click)="deleteAccount()" class="btn btn-pg-light-red">Delete Account</button>
  <button (click)="modal.dismiss()" class="btn btn-primary">Cancel</button>
</div>