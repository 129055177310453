<div
  class="page-header page-header-dark bg-gradient-primary-to-secondary"
  [ngClass]='{ "pb-10": !simplified, "page-header-dark bg-gradient-primary-to-secondary": !light, "page-header-light bg-white shadow": light }'
>
  <div class="container px-4">
    <div class="page-header-content pt-4">
      <div class="row gx-4 align-items-center justify-content-between">
        <div class="col-auto mt-4">
          <h1 class="page-header-title">
            <div class="page-header-icon"><i-feather *ngIf="icon" [name]="icon"></i-feather></div>
            <span>{{ title }}</span>
          </h1>
          <div class="page-header-subtitle">{{ description }}</div>
          <sb-breadcrumbs *ngIf="breadcrumbs"></sb-breadcrumbs>
        </div>
        <div class="col-12 col-xl-auto mt-4"><sbpro-date-range-quick *ngIf="showDateRange"></sbpro-date-range-quick></div>
      </div>
      <div class="page-header-search mt-4" *ngIf="showSearch">
        <div class="input-group input-group-joined">
          <input class="form-control" type="text" placeholder="Search..." aria-label="Search" autofocus />
          <div class="input-group-text"><i-feather name="search"></i-feather></div>
        </div>
      </div>
    </div>
  </div>
</div>
