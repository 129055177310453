import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as rxjs from 'rxjs';
import { finalize, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MutationResult } from 'apollo-angular';
import { PgToastService } from '@common/services/pg-toast.service';

@Injectable()
export class NetworkCallService {
  constructor(
    private readonly pgToastService: PgToastService,
    private readonly spinnerService: NgxSpinnerService
  ) {}

  errorTracer(errorMessage: string) {
    return <T extends any>(obs$: Observable<T>): Observable<T> => obs$.pipe(this.gqlErrorsToaster(errorMessage));
  }

  private gqlErrorsToaster(errorMessage: string) {
    return <T extends any>(obs$: Observable<T>) => {
      return obs$.pipe(
        rxjs.tap((result: any) => {
          if (result?.errors) {
            const messages = [errorMessage];

            result.errors.forEach((err: any) => {
              err.graphQLErrors?.forEach((gqlerr: any) => console.error(gqlerr?.toString()));
              if (err.networkError) {
                const { name, message, cause, stack } = err.networkError;
                messages.push([err.networkError.name, message, cause].join(','));
              }

              this.pgToastService.showError(messages.join('. '));
            });

            // return rxjs.throwError(() => new Error(result.errors))
          }
        })
      );
    };
  }

  mutateWithToastAndSpinner(callToServer: () => Observable<MutationResult<any>>, successMessage: string, errorMessage: string) {
    this.spinnerService.show();
    return callToServer().pipe(
      catchError((err) => {
        this.pgToastService.showError(err);
        this.spinnerService.hide();
        return of(err);
      }),
      tap((result) => {
        this.spinnerService.hide();
        if (result.errors) {
          result.errors.forEach((err: any) => {
            this.pgToastService.showError(errorMessage);
          });
        }
        if (result?.data) {
          this.pgToastService.showSuccess(successMessage);
        }
      })
    );
  }
}
