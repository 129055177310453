import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoggedInUserStateModel } from '@modules/auth/store';
import { NavigationService } from '@modules/navigation/services';
import { Store } from '@ngxs/store';
import { UserRole } from 'generated/graphql';

@Component({
  selector: 'pg-top-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pg-top-nav.component.html',
  styleUrls: ['pg-top-nav.component.scss'],
})
export class PgTopNavComponent implements OnInit {
  route = '/';
  @Input() sideToggle = true;
  role: UserRole | undefined;

  constructor(private navigationService: NavigationService, private store: Store) {}

  ngOnInit() {
    const userState = this.store.selectSnapshot<LoggedInUserStateModel>((state) => state.loggedInUser);
    this.role = userState.user?.role;
    if (userState.user?.role == UserRole.Teacher) {
      this.route = '/teacher';
    } else if (userState.user?.role == UserRole.District) {
      this.route = '/district';
    } else if (userState.user?.role == UserRole.Student) {
      this.route = '/student';
      this.sideToggle = false;
    } else if (userState.user?.role == UserRole.Admin) {
      this.route = '/admin';
    } else if (userState.user?.role == UserRole.User) {
      this.route = '/user';
    }
  }

  toggleSideNav() {
    this.navigationService.toggleSideNav();
  }
}
