import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'pg-toast',
  templateUrl: 'pg-toast.component.html',
  styleUrls: ['pg-toast.component.scss'],
})
export class PgToastComponent {
  @Input() toastType!: 'success' | 'danger' | 'info' | 'warning';
  @Input() text!: string;
  iconClass: string;
  toastHeader: string;

  constructor(
    public notificationRef: MdbNotificationRef<PgToastComponent>,
    private notificationService: MdbNotificationService
  ) {}

  ngOnInit() {
    switch (this.toastType) {
      case 'success':
        this.iconClass = 'fa-circle-check';
        this.toastHeader = 'Success';
        break;
      case 'danger':
        this.iconClass = 'fa-circle-x';
        this.toastHeader = 'Error';
        break;
      case 'info':
        this.iconClass = 'fa-circle-info';
        this.toastHeader = 'Info';
        break;
      case 'warning':
        this.iconClass = 'fa-circle-exclamation';
        this.toastHeader = 'Warning';
        break;
      default:
        this.iconClass = 'fa-circle-check';
        break;
    }
  }

  close() {
    this.notificationRef.close();
  }
}
