import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CheckForPortalNotificationsGQL,
  GetMyPortalNotificationsGQL,
  GetMyPortalNotificationsQuery,
  MarkPortalNotificationAsDeletedGQL,
  UpdatePortalNotificationGQL,
} from '../../../generated/graphql';
import { CheckForNewNotificationsData, LoadNotificationsData, UpdateNotificationDeleted, UpdateNotificationRead } from './notifications.actions';
import { tap } from 'rxjs/operators';

export type Notifications = GetMyPortalNotificationsQuery['getMyPortalNotifications'];

export interface NotificationsStateModel {
  notifications: Notifications;
  status: 'loading' | 'done' | 'failed' | 'new';
  unreadNotifications: number;
}

@State<NotificationsStateModel>({
  name: 'notificationsState',
  defaults: {
    notifications: [],
    unreadNotifications: 0,
    status: 'new',
  },
})
@Injectable()
export class NotificationsState {
  constructor(
    private getMyPortalNotificationsGQL: GetMyPortalNotificationsGQL,
    private checkForPortalNotificationsGQL: CheckForPortalNotificationsGQL,
    private updatePortalNotificationGQL: UpdatePortalNotificationGQL,
    private markPortalNotificationAsDeletedGQL: MarkPortalNotificationAsDeletedGQL
  ) {}

  @Selector()
  static getNotifications(state: NotificationsStateModel) {
    return state.notifications;
  }

  @Selector()
  static getNumberOfUnreadNotifications(state: NotificationsStateModel) {
    return state.unreadNotifications;
  }

  @Action(CheckForNewNotificationsData)
  CheckForNewNotifications(ctx: StateContext<NotificationsStateModel>, { payload }: CheckForNewNotificationsData) {
    return this.checkForPortalNotificationsGQL.fetch({ userId: payload.user }, { fetchPolicy: 'no-cache' }).pipe(
      tap(({ data }) => {
        let notifications: Notifications = data.checkForPortalNotifications;

        let unreadNotifications = 0;

        notifications.forEach((notification) => {
          if (!notification.read) unreadNotifications++;
        });

        notifications = notifications.filter((notification) => {
          if (!notification.deleted) return notification;
        });

        ctx.setState({
          status: 'done',
          notifications,
          unreadNotifications,
        });
      })
    );
  }

  @Action(LoadNotificationsData)
  GetMyNotifications(ctx: StateContext<NotificationsStateModel>) {
    const state = ctx.getState();
    if (!state || state.status === 'loading') {
      return this.getMyPortalNotificationsGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(
        tap(({ data }) => {
          let notifications: Notifications = data.getMyPortalNotifications;

          let unreadNotifications = 0;

          notifications.forEach((notification) => {
            if (!notification.read) unreadNotifications++;
          });

          notifications = notifications.filter((notification) => {
            if (!notification.deleted) return notification;
          });

          ctx.setState({
            status: 'done',
            notifications,
            unreadNotifications,
          });
        })
      );
    }
  }

  @Action(UpdateNotificationRead)
  UpdateNotificationToRead(ctx: StateContext<NotificationsStateModel>, { payload }: UpdateNotificationRead) {
    return this.updatePortalNotificationGQL
      .mutate(
        {
          portalNotificationId: payload.notificationId,
          updatePortalNotificationDto: payload.updateNotificationDto,
        },
        { fetchPolicy: 'no-cache' }
      )
      .pipe(
        tap(({ data }) => {
          ctx.patchState({ status: 'loading' });
          ctx.dispatch(new LoadNotificationsData());
        })
      );
  }

  @Action(UpdateNotificationDeleted)
  UpdateNotificationToDeleted(ctx: StateContext<NotificationsStateModel>, { payload }: UpdateNotificationDeleted) {
    return this.markPortalNotificationAsDeletedGQL.mutate({ portalNotificationId: payload.notificationId }, { fetchPolicy: 'no-cache' }).pipe(
      tap(({ data }) => {
        ctx.patchState({ status: 'loading' });
        ctx.dispatch(new LoadNotificationsData());
      })
    );
  }
}
