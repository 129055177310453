import { LayoutAdminPortalComponent } from './layout-admin-portal/layout-admin-portal.component';
import { LayoutAuthComponent } from './layout-auth/layout-auth.component';
import { LayoutDashboardComponent } from './layout-dashboard/layout-dashboard.component';
import { LayoutErrorComponent } from './layout-error/layout-error.component';
import { LayoutTeacherPortalComponent } from './layout-teacher-portal/layout-teacher-portal.component';
import { LayoutDistrictPortalComponent } from './layout-district-portal/layout-district-portal.component';
import { PgLayoutAuthComponent } from './pg-layout-auth/pg-layout-auth.component';
import { LayoutStudentPortalComponent } from './layout-student-portal/layout-student-portal.component';
import { LayoutHomeComponent } from './layout-home/layout-home.component';
import { LayoutUserPortalComponent } from './layout-user-portal/layout-user-portal.component';

export const layouts = [LayoutDashboardComponent, LayoutAuthComponent, LayoutErrorComponent, LayoutTeacherPortalComponent, LayoutDistrictPortalComponent, PgLayoutAuthComponent, LayoutAdminPortalComponent, LayoutStudentPortalComponent, LayoutHomeComponent, LayoutUserPortalComponent];

export * from './layout-dashboard/layout-dashboard.component';
export * from './layout-auth/layout-auth.component';
export * from './layout-error/layout-error.component';
export * from './layout-teacher-portal/layout-teacher-portal.component';
export * from './pg-layout-auth/pg-layout-auth.component';
export * from './layout-student-portal/layout-student-portal.component';
export * from './layout-home/layout-home.component';
export * from './layout-user-portal/layout-user-portal.component';
