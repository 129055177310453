<div class="container">
  <form [formGroup]="resetPasswordForm">
    <div class="mb-3">
      <label for="newPassword" class="small mb-1">Enter New Password</label>
      <input data-cy="passwordInput" class="form-control" type="password" [class.is-valid]="passwordControlValid" [class.is-invalid]="passwordControlInvalid" formControlName="password"
             id="newPassword"/>
      <div class="invalid-feedback" *ngIf="passwordControl.hasError('required')">Your new password is required.</div>
      <div class="invalid-feedback" *ngIf="passwordControl.hasError('minlength')">Password must be at least 8 characters.</div>
      <div class="invalid-feedback" *ngIf="passwordControl.hasError('isMatch')">Your new password cannot match your current password.</div>
    </div>
    <div class="mb-3">
      <label for="confirmPassword" class="small mb-1">Confirm Password</label>
      <input data-cy="confirmPasswordInput" class="form-control" type="password" [class.is-valid]="confirmPasswordControlValid" [class.is-invalid]="confirmPasswordControlInvalid" formControlName="confirmPassword"
             id="confirmPassword"/>
      <div class="invalid-feedback" *ngIf="confirmPasswordControl.hasError('required')">You must confirm your new password.</div>
      <div class="invalid-feedback" *ngIf="confirmPasswordControl.hasError('minlength')">Password must be at least 8 characters.</div>
      <div class="invalid-feedback" *ngIf="resetPasswordForm.hasError('passwordMismatch')">New Passwords do not match.</div>
    </div>
    <button data-cy="submitNewPasswordBtn" [disabled]="!resetPasswordForm.valid" class="btn btn-primary" type="button" (click)="submitNewPassword()">Reset Password</button>
  </form>
</div>
