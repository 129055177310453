import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageToolsService {
  cookieService = inject(CookieService);
  // The following cookie name is important because it's Google-predefined for the translation engine purpose
  ENGLISH_LOCALE: 'en' = 'en';
  SPANISH_LOCALE: 'es' = 'es';
  COOKIE_NAME: string = 'googtrans';
  COOKIE_EXPIRE: string = 'Fri, 31 Dec 9999 23:59:59 GMT';
  SUPPORTED_LANGUAGES: string[] = [this.ENGLISH_LOCALE, this.SPANISH_LOCALE];

  currentLanguage: WritableSignal<string> = signal('en');
  isSpanish: Signal<boolean> = computed(() => this.currentLanguage() === this.SPANISH_LOCALE);
  languageToSwitchTo: Signal<string> = computed<string>(() => (this.isSpanish() ? this.ENGLISH_LOCALE : this.SPANISH_LOCALE));

  constructor() {}

  getCurrentLanguage(): 'en' | 'es' {
    const googTransCookie = this.getCookie('googtrans');
    if (googTransCookie) {
      const parts = googTransCookie.split('/');
      return parts[2] as 'en' | 'es';
    }
    return this.ENGLISH_LOCALE;
  }

  switchLanguageTo(language: 'en' | 'es'): void {
    this.currentLanguage.set(language);
    this.setGoogTransCookie(language);
  }

  setGoogTransCookie(language: 'en' | 'es'): void {
    const domain = window.location.hostname;
    const cookieValue = `/auto/${language}`;
    document.cookie = `googtrans=${cookieValue};path=/;domain=${domain};expires=${this.COOKIE_EXPIRE}`;
  }

  getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2 && parts !== undefined) {
      return parts.pop()!.split(';').shift();
    }
    return undefined;
  }
}
