import * as i0 from '@angular/core';
import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';

class NgxsBootstrapper {
    constructor() {
        /**
         * Use `ReplaySubject`, thus we can get cached value even if the stream is completed
         */
        this.bootstrap$ = new ReplaySubject(1);
    }
    get appBootstrapped$() {
        return this.bootstrap$.asObservable();
    }
    /**
     * This event will be emitted after attaching `ComponentRef` of the root component
     * to the tree of views, that's a signal that application has been fully rendered
     */
    bootstrap() {
        this.bootstrap$.next(true);
        this.bootstrap$.complete();
    }
}
/** @nocollapse */ NgxsBootstrapper.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsBootstrapper, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
/** @nocollapse */ NgxsBootstrapper.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsBootstrapper, providedIn: 'root' });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsBootstrapper, decorators: [{
            type: Injectable,
            args: [{ providedIn: 'root' }]
        }] });

function defaultEqualityCheck(a, b) {
    return a === b;
}
function areArgumentsShallowlyEqual(equalityCheck, prev, next) {
    if (prev === null || next === null || prev.length !== next.length) {
        return false;
    }
    // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
    const length = prev.length;
    for (let i = 0; i < length; i++) {
        if (!equalityCheck(prev[i], next[i])) {
            return false;
        }
    }
    return true;
}
/**
 * Memoize a function on its last inputs only.
 * Originally from: https://github.com/reduxjs/reselect/blob/master/src/index.js
 *
 * @ignore
 */
function memoize(func, equalityCheck = defaultEqualityCheck) {
    let lastArgs = null;
    let lastResult = null;
    // we reference arguments instead of spreading them for performance reasons
    function memoized() {
        // eslint-disable-next-line prefer-rest-params
        if (!areArgumentsShallowlyEqual(equalityCheck, lastArgs, arguments)) {
            // apply arguments instead of spreading for performance.
            // eslint-disable-next-line prefer-rest-params, prefer-spread
            lastResult = func.apply(null, arguments);
        }
        // eslint-disable-next-line prefer-rest-params
        lastArgs = arguments;
        return lastResult;
    }
    memoized.reset = function () {
        // The hidden (for now) ability to reset the memoization
        lastArgs = null;
        lastResult = null;
    };
    return memoized;
}

class InitialState {
    static set(state) {
        this._value = state;
    }
    static pop() {
        const state = this._value;
        this._value = {};
        return state;
    }
}
InitialState._value = {};
const INITIAL_STATE_TOKEN = new InjectionToken('INITIAL_STATE_TOKEN', {
    providedIn: 'root',
    factory: () => InitialState.pop()
});

// These tokens are internal and can change at any point.
const ɵNGXS_STATE_FACTORY = new InjectionToken('ɵNGXS_STATE_FACTORY');
const ɵNGXS_STATE_CONTEXT_FACTORY = new InjectionToken('ɵNGXS_STATE_CONTEXT_FACTORY');

/**
 * Generated bundle index. Do not edit.
 */

export { INITIAL_STATE_TOKEN, InitialState, NgxsBootstrapper, memoize, ɵNGXS_STATE_CONTEXT_FACTORY, ɵNGXS_STATE_FACTORY };

