import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LogoutUser } from '@modules/auth/store/loggedInUser.actions';
import { LoggedInUserState, LoggedInUser } from '@modules/auth/store';

@Component({
  selector: 'sbpro-top-nav-user',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './top-nav-user.component.html',
  styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent {
  @Select(LoggedInUserState.getLoggedInUser) user$: Observable<LoggedInUser>;
  @Select(LoggedInUserState.UserCharacter) character$: Observable<string>;
  @Select(LoggedInUserState.UserCharacterAvatar) avatar$: Observable<string>;

  @Input() placement = 'bottom-right';
  dropdownClasses: string[] = [];

  constructor(public store: Store) {}

  logout() {
    this.store.dispatch(new LogoutUser());
  }
}
