import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sb-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() dark = false;
  year: string;
  constructor() {
    this.year = new Date().getFullYear().toString();
  }
  ngOnInit() {}
}
