<form [formGroup]="changePasswordForm">
  <div class="mb-3">
    <label for="oldPassword" class="small mb-1">Current Password</label>
    <input class="form-control" type="password" [class.is-valid]="oldPasswordControlValid" [class.is-invalid]="oldPasswordControlInvalid" formControlName="oldPassword"
           id="oldPassword" />
    <div class="invalid-feedback" *ngIf="oldPasswordControl.hasError('required')">Your current password is required.</div>
    <div class="invalid-feedback" *ngIf="oldPasswordControl.hasError('minlength')">Password must be at least 8 characters.</div>
    <div class="invalid-feedback" *ngIf="oldPasswordControl.hasError('isNotMatch')">This password does not match your current password</div>
  </div>
  <div class="mb-3">
    <label for="newPassword" class="small mb-1">Enter New Password</label>
    <input class="form-control" type="password" [class.is-valid]="newPasswordControlValid" [class.is-invalid]="newPasswordControlInvalid" formControlName="newPassword"
           id="newPassword" />
    <div class="invalid-feedback" *ngIf="newPasswordControl.hasError('required')">Your new password is required.</div>
    <div class="invalid-feedback" *ngIf="newPasswordControl.hasError('minlength')">Password must be at least 8 characters.</div>
    <div class="invalid-feedback" *ngIf="changePasswordForm.hasError('oldMatchNew')">Your new password must be different.</div>
  </div>
  <div class="mb-3">
    <label for="confirmPassword" class="small mb-1">Confirm New Password</label>
    <input class="form-control" type="password" [class.is-valid]="confirmPasswordControlValid" [class.is-invalid]="confirmPasswordControlInvalid" formControlName="confirmPassword"
           id="confirmPassword" />
    <div class="invalid-feedback" *ngIf="confirmPasswordControl.hasError('required')">You must confirm your new password.</div>
    <div class="invalid-feedback" *ngIf="confirmPasswordControl.hasError('minlength')">Password must be at least 8 characters.</div>
    <div class="invalid-feedback" *ngIf="changePasswordForm.hasError('passwordMismatch')">New Passwords do not match.</div>
  </div>
  <button [disabled]="!changePasswordForm.valid" class="btn btn-primary" type="button" (click)="submitPassword()">Change Password</button>
</form>

<ng-template #successHeader let-toastService="toastService" , let-toastID="toastID">
  <fa-icon class="me-2" [icon]="['fas', 'check']"></fa-icon>
  <div class="strong me-auto">Your password has been changed</div>
</ng-template>