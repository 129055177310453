<div class="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications"
     ngbDropdown #dropDown="ngbDropdown">
  <button class="btn btn-icon" id="dropdownBasic1" ngbDropdownToggle>
    <img *ngIf="(notifications$ | async)?.unreadNotifications == 0; else unreadNotifications" src="/assets/img/notifications/notification_bubble.svg" height="22" width="27" alt=""
         [ngClass]="dropDown._open ? 'open' : 'close'">
    <ng-template #unreadNotifications>
      <img src="/assets/img/notifications/notification_bubble_unread.svg" height="44" width="44" alt="" [ngClass]="dropDown._open ? 'open' : 'close'">
      <div class="badge">{{ (notifications$ | async)?.unreadNotifications }}</div>
    </ng-template>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <div *ngFor="let notification of (notifications$ | async)?.notifications; index as i">
      <span class="dropdown-option" ngbDropdownItem (click)="openNotification(notificationModal, notification)">
        <span class="px-2">
          <img src="/assets/img/notifications/unread_notification.svg" height="45" width="45" *ngIf="!notification.read" alt="">
          <img src="/assets/img/notifications/read_notification.svg" height="45" width="45" *ngIf="notification.read" alt="" class="open">
        </span>
        <span> {{ notification.title }}</span>
      </span>
      <hr style="margin: 0 auto; height: 2px; color: #c4c4c4; width: 94%;" />
    </div>
  </div>
</div>

<ng-template #notificationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ notificationForModal?.title }}</h5>
    <button class="ms-2 mb-1 btn-close" type="button" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body py-4">{{ notificationForModal?.message.text }}</div>
  <div class="modal-footer">
    <button *ngIf="notificationForModal?.type === 'COMIC'" class="btn btn-primary" type="button" (click)="goToComic(notificationForModal?.attachments[0])">
      Check Out the Comic
    </button>
    <button class="btn btn-pg-light-red" type="button" (click)="archiveNotification()">
      <fa-icon style="color: white; font-size: 1.2rem" [icon]="trash"></fa-icon>
    </button>
  </div>
</ng-template>

