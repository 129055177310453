import { SideNavItems, SideNavSection } from '../../navigation/models';

export const sideNavSections: SideNavSection[] = [
  {
    text: 'HOME',
    items: ['home'],
  },
  {
    text: 'DASHBOARD',
    items: ['dashboard', 'resources', 'lessons', 'sciops', 'classes', 'account'],
  },
];

export const sideNavItems: SideNavItems = {
  home: {
    faIcon: 'home',
    text: 'Home',
    link: '/',
  },
  dashboard: {
    faIcon: 'user-graduate',
    text: 'Teacher Portal',
    link: '/teacher',
  },
  resources: {
    text: 'Teacher Support',
    faIcon: 'books',
    submenu: [
      {
        link: '/teacher/resources/start',
        text: 'Getting Started',
      },
      {
        link: '/teacher/resources/training/home',
        text: 'Training Center',
      },
      {
        link: '/teacher/resources/reading',
        text: 'Strategies',
      },
      {
        link: '/teacher/resources/tutorials',
        text: 'Tutorials',
      },
      {
        link: '/teacher/resources/support',
        text: 'Extra Support',
      },
    ],
  },
  lessons: {
    text: 'Lessons',
    faIcon: 'pencil-alt',
    submenu: [
      {
        link: '/teacher/lessons/home',
        text: 'Power Up Your Lessons',
      },
      {
        link: '/teacher/lessons/activities',
        queryParams: { course: 'ms' },
        text: 'Middle School Activities',
      },
      {
        link: '/teacher/lessons/activities',
        queryParams: { course: 'chem' },
        text: 'HS Chemistry Activities',
      },
      {
        link: '/teacher/lessons/activities',
        queryParams: { course: 'ps' },
        text: 'HS Physical Science Activities',
      },
    ],
  },
  sciops: {
    text: 'Sci-Ops',
    faIcon: 'gamepad',
    submenu: [
      {
        link: '/teacher/sciops/home',
        text: 'Sci-Ops: Global Defense',
      },
      {
        link: '/teacher/sciops/gear-guides',
        text: 'Gear Guides',
      },
      {
        link: '/teacher/sciops/comics',
        text: 'Comics',
      },
    ],
  },
  // units: {
  //   text: 'Units',
  //   faIcon: 'chalkboard-teacher',
  //   submenu: [
  //     {
  //       link: '/teacher/units/activities',
  //       text: 'Student Activities',
  //     },
  //     {
  //       link: '/teacher/units/standards',
  //       text: 'Unit Standards',
  //     },
  //     {
  //       link: '/teacher/units/pacing',
  //       text: 'Pacing Guides',
  //     },
  //   ],
  // },
  classes: {
    text: 'My Classes',
    faIcon: 'users',
    link: '/teacher/classes',
  },
  account: {
    text: 'Account',
    faIcon: 'user-cog',
    link: '/teacher/account',
  },
};
