export enum Characters {
  CHAR_01 = 'char_01',
  CHAR_02 = 'char_02',
  CHAR_03 = 'char_03',
  CHAR_04 = 'char_04',
  CHAR_05 = 'char_05',
  CHAR_06 = 'char_06',
  CHAR_07 = 'char_07',
  CHAR_08 = 'char_08',
  CHAR_09 = 'char_09',
  CHAR_10 = 'char_10',
  CHAR_11 = 'char_11',
  CHAR_12 = 'char_12',
  CHAR_13 = 'char_13',
  CHAR_14 = 'char_14',
  CHAR_15 = 'char_15',
  CHAR_16 = 'char_16',
  CHAR_17 = 'char_17',
  CHAR_18 = 'char_18',
}
