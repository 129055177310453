import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserState } from '../../../../generated/graphql';

@Component({
  selector: 'pg-user-state-badge',
  templateUrl: './user-state-badge.component.html',
  styleUrls: ['user-state-badge.component.scss'],
})
export class UserStateBadgeComponent implements OnInit, OnChanges {
  @Input() state: UserState;
  color: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges) {
    this.getColor();
  }
  ngOnInit() {
    this.getColor();
  }
  getColor() {
    switch (this.state) {
      case UserState.Ready: {
        this.color = 'bg-success';
        this.changeDetectorRef.detectChanges();
        break;
      }
      case UserState.Character: {
        this.color = 'bg-success';
        this.changeDetectorRef.detectChanges();
        break;
      }
      default: {
        this.color = 'bg-danger';
        this.changeDetectorRef.detectChanges();
        break;
      }
    }
  }
}
