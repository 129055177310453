import * as i0 from '@angular/core';
import { InjectionToken, ɵglobal, Injectable, Inject, NgModule } from '@angular/core';
import { Store, getActionTypeFromInstance, InitState, NGXS_PLUGINS } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';

const NGXS_DEVTOOLS_OPTIONS = new InjectionToken('NGXS_DEVTOOLS_OPTIONS');

/**
 * Adds support for the Redux Devtools extension:
 * http://extension.remotedev.io/
 */
class NgxsReduxDevtoolsPlugin {
    constructor(_options, _injector, _ngZone) {
        this._options = _options;
        this._injector = _injector;
        this._ngZone = _ngZone;
        this.devtoolsExtension = null;
        this.globalDevtools = ɵglobal['__REDUX_DEVTOOLS_EXTENSION__'] || ɵglobal['devToolsExtension'];
        this.unsubscribe = null;
        this.connect();
    }
    ngOnDestroy() {
        if (this.unsubscribe !== null) {
            this.unsubscribe();
        }
        if (this.globalDevtools) {
            this.globalDevtools.disconnect();
        }
    }
    /**
     * Lazy get the store for circular dependency issues
     */
    get store() {
        return this._injector.get(Store);
    }
    /**
     * Middleware handle function
     */
    handle(state, action, next) {
        if (!this.devtoolsExtension || this._options.disabled) {
            return next(state, action);
        }
        return next(state, action).pipe(catchError(error => {
            const newState = this.store.snapshot();
            this.sendToDevTools(state, action, newState);
            throw error;
        }), tap(newState => {
            this.sendToDevTools(state, action, newState);
        }));
    }
    sendToDevTools(state, action, newState) {
        const type = getActionTypeFromInstance(action);
        // if init action, send initial state to dev tools
        const isInitAction = type === InitState.type;
        if (isInitAction) {
            this.devtoolsExtension.init(state);
        }
        else {
            this.devtoolsExtension.send(Object.assign(Object.assign({}, action), { action: null, type }), newState);
        }
    }
    /**
     * Handle the action from the dev tools subscription
     */
    dispatched(action) {
        if (action.type === "DISPATCH" /* Dispatch */) {
            if (action.payload.type === "JUMP_TO_ACTION" /* JumpToAction */ ||
                action.payload.type === "JUMP_TO_STATE" /* JumpToState */) {
                const prevState = JSON.parse(action.state);
                // This makes the DevTools and Router plugins compatible with each other.
                // We check for the existence of the `router` state and ensure it has the
                // `trigger` property, confirming that it is our router state (coming from `@ngxs/router-plugin`).
                // This enables a time-traveling feature, as it not only restores the state but
                // also allows the `RouterState` to navigate back when the action is jumped.
                if (prevState.router && prevState.router.trigger) {
                    prevState.router.trigger = 'devtools';
                }
                this.store.reset(prevState);
            }
            else if (action.payload.type === "TOGGLE_ACTION" /* ToggleAction */) {
                console.warn('Skip is not supported at this time.');
            }
            else if (action.payload.type === "IMPORT_STATE" /* ImportState */) {
                const { actionsById, computedStates, currentStateIndex } = action.payload.nextLiftedState;
                this.devtoolsExtension.init(computedStates[0].state);
                Object.keys(actionsById)
                    .filter(actionId => actionId !== '0')
                    .forEach(actionId => this.devtoolsExtension.send(actionsById[actionId], computedStates[actionId].state));
                this.store.reset(computedStates[currentStateIndex].state);
            }
        }
        else if (action.type === "ACTION" /* Action */) {
            const actionPayload = JSON.parse(action.payload);
            this.store.dispatch(actionPayload);
        }
    }
    connect() {
        if (!this.globalDevtools || this._options.disabled) {
            return;
        }
        // The `connect` method adds a `message` event listener to communicate
        // with an extension through `window.postMessage` and handle message events.
        // Since we only handle two specific events, we aim to avoid unnecessary change
        // detections triggered by events that the extension sends, but we don't need to handle.
        this.devtoolsExtension = this._ngZone.runOutsideAngular(() => this.globalDevtools.connect(this._options));
        this.unsubscribe = this.devtoolsExtension.subscribe(action => {
            if (action.type === "DISPATCH" /* Dispatch */ ||
                action.type === "ACTION" /* Action */) {
                this.dispatched(action);
            }
        });
    }
}
/** @nocollapse */ NgxsReduxDevtoolsPlugin.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPlugin, deps: [{ token: NGXS_DEVTOOLS_OPTIONS }, { token: i0.Injector }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Injectable });
/** @nocollapse */ NgxsReduxDevtoolsPlugin.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPlugin });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPlugin, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [NGXS_DEVTOOLS_OPTIONS]
                }] }, { type: i0.Injector }, { type: i0.NgZone }]; } });

function devtoolsOptionsFactory(options) {
    return Object.assign({ name: 'NGXS' }, options);
}
const USER_OPTIONS = new InjectionToken('USER_OPTIONS');
class NgxsReduxDevtoolsPluginModule {
    static forRoot(options) {
        return {
            ngModule: NgxsReduxDevtoolsPluginModule,
            providers: [
                {
                    provide: NGXS_PLUGINS,
                    useClass: NgxsReduxDevtoolsPlugin,
                    multi: true
                },
                {
                    provide: USER_OPTIONS,
                    useValue: options
                },
                {
                    provide: NGXS_DEVTOOLS_OPTIONS,
                    useFactory: devtoolsOptionsFactory,
                    deps: [USER_OPTIONS]
                }
            ]
        };
    }
}
/** @nocollapse */ NgxsReduxDevtoolsPluginModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPluginModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
/** @nocollapse */ NgxsReduxDevtoolsPluginModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPluginModule });
/** @nocollapse */ NgxsReduxDevtoolsPluginModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPluginModule });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsReduxDevtoolsPluginModule, decorators: [{
            type: NgModule
        }] });

/**
 * The public api for consumers of @ngxs/devtools-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NGXS_DEVTOOLS_OPTIONS, NgxsReduxDevtoolsPlugin, NgxsReduxDevtoolsPluginModule };

