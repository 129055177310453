<div class="toast mx-auto" role="alert" aria-live="assertive" aria-atomic="true" [attr.data-cy]="'toast-' + toastType">
  <div class="pg-toast-{{ toastType }} d-flex align-items-stretch justify-content-center shadow-1 rounded">
    <div class="py-3 px-3 align-items-center">
      <span class="pg-toast-icon-container">
        <i class="fa-2x pg-toast-icon fas" [ngClass]="iconClass"></i>
      </span>
    </div>
    <div class="flex-grow-1 align-self-center my-1 pe-1">
      <h5 class="my-0 pg-toast-message">{{ toastHeader }}</h5>
      <div class="my-0 lh-1 fw-normal description">{{ text }}</div>
    </div>
    <div class="btn-container p-2 rounded-end align-items-center">
      <button type="button" class="btn-close h-100 py-0 fs-4" aria-label="Close dialog" (click)="notificationRef.close()">X</button>
    </div>
  </div>
</div>
