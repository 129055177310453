import {
  faArrowDownToLine,
  faArrowRightToLine,
  faBell,
  faCaretDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEnvelopeOpen,
  faFileMagnifyingGlass,
  faFlask,
  faMicroscope,
  faNewspaper,
  faShareNodes,
  faSliders,
  faSquareCheck,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';

export const fontAwesomeLightIcons = {
  faArrowRightToLine,
  faArrowDownToLine,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faFileMagnifyingGlass,
  faFlask,
  faNewspaper,
  faShareNodes,
  faBell,
  faEnvelopeOpen,
  faSliders,
  faTrashCan,
  faMicroscope,
  faSquareCheck,
};
