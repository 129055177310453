import { __awaiter, __generator } from "tslib";
import { DEFAULT_FORM_START_EVENT, DEFAULT_FORM_SUBMIT_EVENT, FORM_ID, FORM_NAME, FORM_DESTINATION, } from '../constants';
import { getGlobalScope } from '@amplitude/analytics-client-common';
export var formInteractionTracking = function () {
    var observer;
    var eventListeners = [];
    var addEventListener = function (element, type, handler) {
        element.addEventListener(type, handler);
        eventListeners.push({
            element: element,
            type: type,
            handler: handler,
        });
    };
    var removeClickListeners = function () {
        eventListeners.forEach(function (_a) {
            var element = _a.element, type = _a.type, handler = _a.handler;
            /* istanbul ignore next */
            element === null || element === void 0 ? void 0 : element.removeEventListener(type, handler);
        });
        eventListeners = [];
    };
    var name = '@amplitude/plugin-form-interaction-tracking-browser';
    var type = 'enrichment';
    var setup = function (config, amplitude) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            // The form interaction plugin observes changes in the dom. For this to work correctly, the observer can only be setup
            // after the body is built. When Amplitud gets initialized in a script tag, the body tag is still unavailable. So register this
            // only after the window is loaded
            /* istanbul ignore next */
            (_a = getGlobalScope()) === null || _a === void 0 ? void 0 : _a.addEventListener('load', function () {
                /* istanbul ignore if */
                if (!amplitude) {
                    // TODO: Add required minimum version of @amplitude/analytics-browser
                    config.loggerProvider.warn('Form interaction tracking requires a later version of @amplitude/analytics-browser. Form interaction events are not tracked.');
                    return;
                }
                /* istanbul ignore if */
                if (typeof document === 'undefined') {
                    return;
                }
                var addFormInteractionListener = function (form) {
                    var hasFormChanged = false;
                    addEventListener(form, 'change', function () {
                        var _a;
                        if (!hasFormChanged) {
                            amplitude.track(DEFAULT_FORM_START_EVENT, (_a = {},
                                _a[FORM_ID] = stringOrUndefined(form.id),
                                _a[FORM_NAME] = stringOrUndefined(form.name),
                                _a[FORM_DESTINATION] = form.action,
                                _a));
                        }
                        hasFormChanged = true;
                    });
                    addEventListener(form, 'submit', function () {
                        var _a, _b;
                        if (!hasFormChanged) {
                            amplitude.track(DEFAULT_FORM_START_EVENT, (_a = {},
                                _a[FORM_ID] = stringOrUndefined(form.id),
                                _a[FORM_NAME] = stringOrUndefined(form.name),
                                _a[FORM_DESTINATION] = form.action,
                                _a));
                        }
                        amplitude.track(DEFAULT_FORM_SUBMIT_EVENT, (_b = {},
                            _b[FORM_ID] = stringOrUndefined(form.id),
                            _b[FORM_NAME] = stringOrUndefined(form.name),
                            _b[FORM_DESTINATION] = form.action,
                            _b));
                        hasFormChanged = false;
                    });
                };
                // Adds listener to existing anchor tags
                var forms = Array.from(document.getElementsByTagName('form'));
                forms.forEach(addFormInteractionListener);
                // Adds listener to anchor tags added after initial load
                /* istanbul ignore else */
                if (typeof MutationObserver !== 'undefined') {
                    observer = new MutationObserver(function (mutations) {
                        mutations.forEach(function (mutation) {
                            mutation.addedNodes.forEach(function (node) {
                                if (node.nodeName === 'FORM') {
                                    addFormInteractionListener(node);
                                }
                                if ('querySelectorAll' in node && typeof node.querySelectorAll === 'function') {
                                    Array.from(node.querySelectorAll('form')).map(addFormInteractionListener);
                                }
                            });
                        });
                    });
                    observer.observe(document.body, {
                        subtree: true,
                        childList: true,
                    });
                }
            });
            return [2 /*return*/];
        });
    }); };
    var execute = function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, event];
    }); }); };
    var teardown = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            observer === null || observer === void 0 ? void 0 : observer.disconnect();
            removeClickListeners();
            return [2 /*return*/];
        });
    }); };
    return {
        name: name,
        type: type,
        setup: setup,
        execute: execute,
        teardown: teardown,
    };
};
export var stringOrUndefined = function (name) {
    /* istanbul ignore if */
    if (typeof name !== 'string') {
        // We found instances where the value of `name` is an Element and not a string.
        // Elements may have circular references and would throw an error when passed to `JSON.stringify(...)`.
        // If a non-string value is seen, assume there is no value.
        return undefined;
    }
    return name;
};
