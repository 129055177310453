import { Injectable, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ClassroomExistGQL, CreateStudentGQL, IsUsernameAvailableGQL, UserCreationType } from 'generated/graphql';
import { Observable } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { StudentSignupFormModel } from '../models';
import { error } from 'console';

@Injectable()
export class StudentSignupService {
  isUsernameAvailableGQL = inject(IsUsernameAvailableGQL);
  classroomExistGQL = inject(ClassroomExistGQL);
  createStudentGQL = inject(CreateStudentGQL);

  emailAvailabilityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkEmailAvailability(control.value).pipe(
        map((res) => {
          return res ? null : { emailExists: true };
        })
      );
    };
  }

  classroomExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkClassroomExist(control.value).pipe(
        map((res) => {
          return res ? null : { classroomNotExist: true };
        })
      );
    };
  }

  checkEmailAvailability(email: string): Observable<boolean> {
    return this.isUsernameAvailableGQL.fetch({ username: email }, { fetchPolicy: 'no-cache' }).pipe(
      debounceTime(500),
      take(1),
      map(({ data }) => {
        return data.isUsernameAvailable;
      })
    );
  }

  checkClassroomExist(classCode: string): Observable<boolean> {
    return this.classroomExistGQL.fetch({ getClassroom: { classCode } }).pipe(
      debounceTime(500),
      take(1),
      map(({ data }) => {
        return data.classroomExist;
      })
    );
  }

  postStudentAcct(createStudent: StudentSignupFormModel, classCode: string, creationType: UserCreationType): Observable<any> {
    return this.createStudentGQL.mutate({ createStudent, classCode, creationType: UserCreationType.InviteCode }, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => {
        return data?.createStudent;
      })
    );
  }
}
