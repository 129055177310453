import { Injectable } from '@angular/core';
import { MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { PgToastComponent } from '@common/components/pg-toast/pg-toast.component';

@Injectable()
export class PgToastService {
  constructor(private mdbNotificationService: MdbNotificationService) {}

  showSuccess(message: string) {
    this.mdbNotificationService.open(PgToastComponent, { stacking: true, autohide: true, delay: 5000, data: { toastType: 'success', text: message } });
  }

  showError(message: string) {
    this.mdbNotificationService.open(PgToastComponent, { stacking: true, autohide: true, delay: 5000, data: { toastType: 'danger', text: message } });
  }

  showWarning(message: string) {
    this.mdbNotificationService.open(PgToastComponent, { stacking: true, autohide: true, delay: 5000, data: { toastType: 'warning', text: message } });
  }

  showInfo(message: string) {
    this.mdbNotificationService.open(PgToastComponent, { stacking: true, autohide: true, delay: 5000, data: { toastType: 'info', text: message } });
  }
}
