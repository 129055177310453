import { Injectable } from '@angular/core';
import { CreateClickTrackerDto, CreateClickTrackerGQL } from 'generated/graphql';

@Injectable()
export class ClickTrackerService {
  constructor(private createClickTrackerGQL: CreateClickTrackerGQL) {}

  recordClickTracker(createClickTrackerDto: CreateClickTrackerDto) {
    this.createClickTrackerGQL.mutate({ createClickTrackerDto }, { errorPolicy: 'all' }).subscribe();
  }
}
