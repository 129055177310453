import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggedInUser } from '@modules/auth/store';
import { RefreshUserData } from '@modules/auth/store/loggedInUser.actions';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ComparePasswordGQL, GetUserDto, UpdateUserGQL, UpdateUserPasswordGQL, UserState } from '../../../../generated/graphql';

@Component({
  selector: 'pg-user-reset-password',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit {
  subs = new SubSink();

  resetPasswordForm: UntypedFormGroup;

  user: LoggedInUser;

  @ViewChild('successHeader') successHeader: TemplateRef<any>;

  constructor(
    private store: Store,
    private router: Router,
    private updateUserPasswordGQL: UpdateUserPasswordGQL,
    private comparePasswordGQL: ComparePasswordGQL,
    private updateUserGQL: UpdateUserGQL,
    private spinnerService: NgxSpinnerService
  ) {
    this.resetPasswordForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)], [this.compareOldPasswordWithNew.bind(this)]),
        confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      },
      {
        validators: [this.checkPassMatchConfirm],
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser.user);
  }

  checkPassMatchConfirm: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password') as UntypedFormControl;
    const confirmPassword = control.get('confirmPassword') as UntypedFormControl;
    return password && confirmPassword && password.value === confirmPassword.value ? null : { passwordMismatch: true };
  };

  compareOldPasswordWithNew(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.comparePasswordGQL.fetch({ getUser: { _id: this.user._id }, password: control.value }, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => {
        return data.compareUserPassword ? { isMatch: true } : null;
      })
    );
  }

  submitNewPassword() {
    this.spinnerService.show();
    const newPassword = this.passwordControl.value;
    const getUser: GetUserDto = { _id: this.user._id };
    this.subs.sink = this.updateUserPasswordGQL.mutate({ getUser, newPassword }).subscribe(({ data }) => {
      this.subs.sink = this.updateUserGQL.mutate({ getUser, updateUser: { state: UserState.Ready } }).subscribe(({ data }) => {
        if (data?.updateUser) {
          this.spinnerService.hide();
          this.refreshUserState();
        }
      });
    });
  }

  get passwordControl() {
    return this.resetPasswordForm.get('password') as UntypedFormControl;
  }

  get passwordControlValid() {
    return this.passwordControl.touched && !this.passwordControlInvalid;
  }

  get passwordControlInvalid() {
    return this.passwordControl.touched && (this.passwordControl.hasError('required') || this.passwordControl.hasError('minlength') || this.passwordControl.hasError('isMatch'));
  }

  get confirmPasswordControl() {
    return this.resetPasswordForm.get('confirmPassword') as UntypedFormControl;
  }

  get confirmPasswordControlValid() {
    return this.confirmPasswordControl.touched && !this.confirmPasswordControlInvalid;
  }

  get confirmPasswordControlInvalid() {
    return (
      this.confirmPasswordControl.touched &&
      (this.confirmPasswordControl.hasError('required') || this.confirmPasswordControl.hasError('minlength') || this.resetPasswordForm.hasError('passwordMismatch'))
    );
  }

  private async refreshUserState() {
    await firstValueFrom(this.store.dispatch(new RefreshUserData()));
    this.router.navigate(['/auth/login']);
  }
}
