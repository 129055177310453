import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserRole } from '../../../../generated/graphql';

@Component({
  selector: 'pg-user-role-badge',
  templateUrl: './user-role-badge.component.html',
  styleUrls: ['user-role-badge.component.scss'],
})
export class UserRoleBadgeComponent implements OnInit, OnChanges {
  @Input() role: UserRole;
  color: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges) {
    this.getColor();
  }
  ngOnInit() {
    this.getColor();
  }
  getColor() {
    switch (this.role) {
      case UserRole.Admin: {
        this.color = 'bg-pg-light-red';
        this.changeDetectorRef.detectChanges();
        break;
      }
      case UserRole.District: {
        this.color = 'bg-secondary';
        this.changeDetectorRef.detectChanges();
        break;
      }
      case UserRole.Student: {
        this.color = 'bg-pg-lavender';
        this.changeDetectorRef.detectChanges();
        break;
      }
      case UserRole.Teacher: {
        this.color = 'bg-success';
        this.changeDetectorRef.detectChanges();
        break;
      }
      case UserRole.User: {
        this.color = 'bg-primary';
        this.changeDetectorRef.detectChanges();
        break;
      }
      default: {
        this.color = 'bg-dark';
        this.changeDetectorRef.detectChanges();
        break;
      }
    }
  }
}
