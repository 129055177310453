import { Directive, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { SubscriptionLike } from 'rxjs';

@Directive()
export class SubSinkBase implements OnDestroy {
  subs = new SubSink();
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  protected addSubToSink(sub: SubscriptionLike) {
    this.subs.add(sub);
  }
}
